import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const login = (payload) => ({
    type: actionTypes.LOGIN_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const loginSuccess = (payload) => ({
    type: actionTypes.LOGIN_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const loginFailure = (payload) => ({
    type: actionTypes.LOGIN_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const loginReset = () => ({
    type: actionTypes.LOGIN_RESET,
});

// Action creator for resetting all OTP generation reducer
export const loginResetAll = () => ({
    type: actionTypes.LOGIN_RESET_ALL,
}); 