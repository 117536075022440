import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const createUser = (payload) => ({
    type: actionTypes.CREATE_USER_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const createUserSuccess = (payload) => ({
    type: actionTypes.CREATE_USER_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const createUserFailure = (payload) => ({
    type: actionTypes.CREATE_USER_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const createUserReset = () => ({
    type: actionTypes.CREATE_USER_RESET,
});

// Action creator for resetting all OTP generation reducer
export const createUserResetAll = () => ({
    type: actionTypes.CREATE_USER_RESET_ALL,
}); 