import React, { useEffect, useState } from 'react';
import { LoginPageImg } from '../../static/constant/imgConstants';
import Success from './Success';  // Importing Success Component
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { forgetPasswordReset, forgetPasswordResetReset } from '../../store/actions/authActions/forgetPasswordResetActions';
import { useToast } from '../../hooks/useToast';

function ResetPassword() {
    const dispatch = useDispatch();
    const { userType } = useParams();
    const Toast = useToast();
    const navigate = useNavigate();

    const forgetPasswordResetReducer = useSelector(state => state.forgetPasswordResetReducer)
    const utilReducers = useSelector(state => state.utilReducers)

    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [errors, setErrors] = useState({});

    const validateForm = (data) => {
        let formErrors = {};
    
        // Validate Password
        if (!data.password) {
            formErrors.password = 'Password is required';
        } else if (data.password.length < 6) {
            formErrors.password = 'Password should be at least 6 characters';
        }
        // Validate Confirm Password
        if (!data.confirmPassword) {
            formErrors.confirmPassword = 'Please confirm your password';
        } else if (data.confirmPassword !== data.password) {
            formErrors.confirmPassword = 'Passwords do not match';
        }

        return formErrors;
    };


    const handlePasswordReset = (e) => {
        e.preventDefault();
        
        const formData = {
            password: e.target.elements.password.value,
            confirmPassword: e.target.elements.confirmPassword.value,
        };
        
        const formErrors = validateForm(formData);
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            setErrors({});
            // If no errors, proceed with registration
            console.log("Registering User:", formData);

            dispatch(forgetPasswordReset({
                "sessionCode": utilReducers.sessionToken,
                "email": utilReducers.emailForForgetPassword,
                "isBrand": userType === "brand",
                "password": formData.password,
            }));
        }
    };

    useEffect(() => {
        if(forgetPasswordResetReducer.status === 200){
            Toast(forgetPasswordResetReducer.message, 'success' )
            dispatch(forgetPasswordResetReset())
            navigate('/login')
        }else if(forgetPasswordResetReducer.status === 403){
            Toast(forgetPasswordResetReducer.message, 'error' )
            dispatch(forgetPasswordResetReset())
        }else if(forgetPasswordResetReducer.status){
            Toast(forgetPasswordResetReducer.message, 'info' )
            dispatch(forgetPasswordResetReset())
        }
    },[forgetPasswordResetReducer]);

    return (
        <>
            {isPasswordReset ? (
                <Success />  // If password reset, show success message
            ) : (
                <div className='flex min-h-[80rem] h-[100vh]'>
                    <div className='flex flex-col justify-start items-center w-[58%] h-full px-[4.8rem] py-[3rem]'>
                        <div className='logo-container w-full flex justify-start items-center'>
                            <div className='logo w-[8rem]'>
                                <img className="w-full h-full" alt="" src={LoginPageImg.login1} />
                            </div>
                        </div>
                        <div className='flex flex-grow flex-col justify-start items-start text-[1.6rem] w-[40%] max-h-[90%]'>
                            <h2 className="text-[1.6rem] text-start md:text-[1.8rem] font-semibold mb-3">
                                Reset Password
                            </h2>
                            <p className="text-[1.4rem] text-start md:text-[1.6rem] text-[#313957] mb-4">
                                Set the new password for your account so you can log in and access all features.
                            </p>
                            <form onSubmit={handlePasswordReset} className='w-full my-[2rem]'>
                                <div className={`w-full mb-[1rem]`}>
                                    <input
                                        type="password"
                                        name="password" 
                                        placeholder="Password"
                                        className={`w-full px-[1.6rem] py-[.8rem] bg-[#F3F9FA] border rounded-[1.2rem] focus:outline-none ${errors.password ? 'border-red-500' : 'border-gray-300'}`}
                                    />
                                    {errors.password && <p className="text-right text-red-500 text-sm">{errors.password}</p>}
                                </div>
                                <div className={`w-full mb-[1rem]`}>
                                    <input
                                        type="password"
                                        name="confirmPassword" 
                                        placeholder="Confirm Password"
                                        className={`w-full px-[1.6rem] py-[.8rem] bg-[#F3F9FA] border rounded-[1.2rem] focus:outline-none ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'}`}
                                        />
                                    {errors.confirmPassword && <p className="text-right text-red-500 text-sm">{errors.confirmPassword}</p>}
                                </div>
                                <button
                                    type="submit"
                                    className="w-full bg-[#0D9B50] text-[2rem] text-white py-3 mb-4 mt-[1rem] lg:mt-[2rem] rounded-[1.2rem] hover:bg-green-700"
                                >
                                   Reset Password
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className='flex flex-col justify-start items-center w-[42%] h-full'>
                        <img src={LoginPageImg.loginlogo} alt="" className="w-full" />
                    </div>
                </div>
            )}
        </>
    );
}

export default ResetPassword;
