import React, { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FaCircleExclamation, FaRegHourglassHalf } from "react-icons/fa6";

const VerifyPopup = ({ title, message,  isOpen, modalAction, type }) => {
  return (
    isOpen && (
      <div className="fixed inset-0 z-50 flex justify-center items-center bg-[#0D020266]">
        <div className="bg-white rounded-lg p-6 md:p-8 shadow-lg w-[30%] h-auto">
          <h2 className="text-[2rem] inter-font font-[500] text-[#202224] ml-[1rem]">
            {title ? title : 'Message'}
          </h2>
          <div className="flex justify-center items-center mt-[4rem] my-[2rem]">
            {type == 'error' ?
                <FaCircleExclamation color="#FF0000" size='10rem'/>
              :type == 'warning' ?
                <FaRegHourglassHalf color="#FFDA76" size='10rem'/>
              :
                <FaCheckCircle color="#0D9B50" size='10rem'/>
            }
          </div>
          <p className="text-center w-[70%] mx-auto text-[1.6rem] inter-font font-[400] text-[#313957] mb-[3rem]">
           {message ? message : "Your Account will be verified by Admin"}
          </p>
          <button
            onClick={modalAction}
            className={`
              w-full ${type == 'eror' ? 'bg-[#FF0000]' :type == 'warning' ? 'bg-[#FFDA76]' : 'bg-[#0D9B50]'} 
              w-full ${type == 'eror' ? 'hover:bg-[#C80036]' :type == 'warning' ? 'hover:bg-[#FFAF45]' : 'hover:bg-[#00712D]'} 
              text-white text-[2rem] inter-font font-[400]  rounded-[1.2rem] px-8 py-3 my-[2rem]
            `}
          >
            Done
          </button>
        </div>
      </div>
    )
  );
};

export default VerifyPopup;
