import { actionTypes } from "../../types";

export const logout = () => ({
    type: actionTypes.USER_LOGOUT
});

export const setAccessToken = (payload) => ({
    type: actionTypes.SET_ACCESS_TOKEN,
    payload: payload
});

export const setSessonToken = (payload) => ({
    type: actionTypes.SET_SESSON_TOKEN,
    payload: payload
});

export const setEmailForLogin = (payload) => ({
    type: actionTypes.SET_EMAIL_FOR_LOGIN,
    payload: payload
});

export const setEmailForForgetPassword = (payload) => ({
    type: actionTypes.SET_EMAIL_FOR_FORGET_PASSWORD,
    payload: payload
});

export const setUserId = (payload) => ({
    type: actionTypes.SET_USER_ID,
    payload: payload
});
