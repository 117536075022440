import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { LoginPageImg } from '../../static/constant/imgConstants';
import { useNavigate } from 'react-router';
import { useToast } from '../../hooks/useToast';
import { useDispatch, useSelector } from 'react-redux';
import PublicHeader from '../../components/utility/PublicHeader/PublicHeader';
import { getAllCountry } from '../../store/actions/publicActions/getAllCountryActions';
import DropdownWithSearch from '../../components/common/DropdownWithSearch/DropdownWithSearch';
import { sendOtpToCreateUser, sendOtpToCreateUserReset } from '../../store/actions/authActions/sendOtpToCreateUserActions';

const Registration = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Toast = useToast();
    
    const sendOtpToCreateUserReducer = useSelector(state => state.sendOtpToCreateUserReducer);
    const getAllCountryReducer = useSelector(state => state.getAllCountryReducer);
    const utilReducers = useSelector(state => state.utilReducers);

    const [loginType, setLoginType] = useState('Influencer'); // Default to 'Influencer'
    const [loginDailog1, setLoginDailog1] = useState('Unleash Influencer, Forge Connections.');
    const [countryCode, setCountryCode] = useState({}); // State for country code and phone number
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errors, setErrors] = useState({});

    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            try {
                console.log('Google Token Response:', tokenResponse);
            } catch (error) {
                console.error('Error fetching Google user info:', error);
            }
        },
        onError: errorResponse => console.log('Google Login Error', errorResponse),
    });

    const validateForm = (data) => {
        let formErrors = {};
        // Validate First Name
        if (!data.firstName.trim()) {
            formErrors.firstName = 'First Name is required';
        }
        // Validate Last Name
        if (!data.lastName.trim()) {
            formErrors.lastName = 'Last Name is required';
        }
        // Validate Email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!data.email.trim()) {
            formErrors.email = 'Email is required';
        } else if (!emailRegex.test(data.email)) {
            formErrors.email = 'Enter a valid email address';
        }
        // Validate Phone Number
        if (!data.phoneNumber.trim()) {
            formErrors.phoneNumber = 'Phone number is required';
        } else if (!/^\d{10}$/.test(data.phoneNumber)) {
            formErrors.phoneNumber = 'Enter a valid 10-digit phone number';
        }

        return formErrors;
    };

    const handleRegister = (e) => {
        e.preventDefault();
        
        const formData = {
            firstName: e.target.elements.firstName.value,
            lastName: e.target.elements.lastName.value,
            email: e.target.elements.email.value,
            phoneNumber: phoneNumber,
        };

        // Log form data for debugging
        console.log("Form Data:", formData);

        const formErrors = validateForm(formData);
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            setErrors({});
            // Log the dispatch action for debugging
            console.log("Dispatching sendOtpToCreateUser with data:", {
                "firstName": formData.firstName,
                "lastName": formData.lastName,
                "email": formData.email,
                "countryId": countryCode.countryId ? countryCode.countryId : 1,
                "phoneNumber": formData.phoneNumber,
                "isBrand": loginType === "Brand"
            });

            dispatch(sendOtpToCreateUser({
                "firstName": formData.firstName,
                "lastName": formData.lastName,
                "email": formData.email,
                "countryId": countryCode.countryId || 1,
                "phoneNumber": formData.phoneNumber,
                "isBrand": loginType === "Brand"
            }));
        }
    };

    useEffect(() => {
        if (sendOtpToCreateUserReducer.status === 200) {
            Toast(sendOtpToCreateUserReducer.message, 'success');
            dispatch(sendOtpToCreateUserReset());
            navigate('/verifyOtp');
        } else if (sendOtpToCreateUserReducer.status === 403) {
            Toast(sendOtpToCreateUserReducer.message, 'error');
            dispatch(sendOtpToCreateUserReset());
        } else if (sendOtpToCreateUserReducer.status === 302) {
            Toast(sendOtpToCreateUserReducer.message, 'error');
            dispatch(sendOtpToCreateUserReset());
        } else if (sendOtpToCreateUserReducer.status) {
            Toast(sendOtpToCreateUserReducer.message, 'info');
            dispatch(sendOtpToCreateUserReset());
        }

        // Log the reducer state for debugging
        console.log('sendOtpToCreateUserReducer:', sendOtpToCreateUserReducer);

    }, [sendOtpToCreateUserReducer, dispatch, navigate, Toast]);


    useEffect(() => {
        dispatch(getAllCountry())
    },[])

    console.log('getAllCountryReducer  :', getAllCountryReducer?.data);
    console.log('countryCode  :', countryCode);

    return (
        <div className='flex flex-col lg:flex-row items-center lg:items-start w-full h-full overflow-auto lg:overflow-hidden'>
            <div className='flex flex-col justify-center lg:justify-start items-center w-full lg:w-[58%] min-h-max xl:h-full px-[4.8rem] py-[3rem]'>
                <PublicHeader />
                <div className='flex flex-grow flex-col justify-between items-center gap-auto lg:gap-[0] xl:gap-[1rem] text-[1.6rem] w-[90%] md:w-[60%] max-h-min xl:min-h-max mt-[1rem]'>
                    <div className='login-nav flex justify-between items-center w-full '>
                        <h4
                            className={`text-[1.8rem] inter-font font-[600] cursor-pointer ${loginType === 'Brand' ? 'text-green-600 border-b-2 border-green-600' : 'text-gray-500'}`}
                            onClick={() => {setLoginType('Brand');setLoginDailog1('Unleash Brand, Forge Connections.')}}
                        >
                            Brand
                        </h4>
                        <h4
                            className={`text-[1.8rem] inter-font font-[600] cursor-pointer ${loginType === 'Influencer' ? 'text-green-600 border-b-2 border-green-600' : 'text-gray-500'}`}
                            onClick={() => {setLoginType('Influencer');setLoginDailog1('Unleash Influencer, Forge Connections.')}}
                        >
                            Influencer
                        </h4>
                        <h4
                            className={`text-[1.8rem] cursor-pointer ${loginType === 'Influencer' ? 'text-green-600 border-b-2 border-green-600' : 'text-gray-500'}`}
                            onClick={() => {}}
                        >
                            
                        </h4>
                    </div>
                    <div className='flex flex-col items-start w-full my-[2rem] lg:my-auto 2xl:my-[2rem] '>
                        <h2 className="text-[1.6rem] inter-font font-[500] text-[#9B9DA1]">{loginDailog1}</h2>
                        <p className="text-[1.6rem] inter-font font-[400]">
                            Please provide below details to secure your verified PaidPromo account.
                        </p>
                    </div>
                    <form onSubmit={handleRegister} className='flex flex-wrap justify-between gap-2 items-center w-full !overflow-visible'>
                        <div className='w-full flex justify-between'>
                        <div className={`w-[48%] mb-[1rem]`}>
                        <label className="text-[1.6rem] roboto-thin font-[400] text-[#111729]">First Name</label>
                            <input
                                type="text"
                                name="firstName" 
                                placeholder="First Name"
                                className={`w-full px-[1.6rem] roboto-thin  font-[400] py-[.8rem] bg-[#F3F9FA] border rounded-[1.2rem] focus:outline-none ${errors.firstName ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            {errors.firstName && <p className="text-right text-red-500 text-sm">{errors.firstName}</p>}
                        </div>
                        <div className={`w-[48%] mb-[1rem]`}> 
                        <label className="text-[1.6rem] roboto-thin  font-[400] text-[#111729]">Last Name</label>   
                            <input
                                type="text"
                                name="lastName" 
                                placeholder="Last Name"
                                className={`w-full px-[1.6rem] py-[.8rem] roboto-thin  font-[400] bg-[#F3F9FA] border rounded-[1.2rem] focus:outline-none ${errors.lastName ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            {errors.lastName && <p className="text-right text-red-500 text-sm">{errors.lastName}</p>}
                        </div>
                        </div>
                        <div className={`w-full mb-[1rem]`}>
                        <label className="text-[1.6rem] roboto-thin  font-[400] text-[#111729]">Email ID</label>
                            <input
                                type="email"
                                name="email" 
                                placeholder="Email"
                                className={`w-full px-[1.6rem] roboto-thin  font-[400] py-[.8rem] bg-[#F3F9FA] border rounded-[1.2rem] focus:outline-none ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            {errors.email && <p className="text-right text-red-500 text-sm">{errors.email}</p>}
                        </div>
                        <div className={`w-full mb-[1rem] !overflow-visible`}>
                            <label className="text-[1.6rem] text-[#111729] roboto-thin  font-[400]">Mobile Number</label>
                            <div className={`flex items-center roboto-thin  font-[400] w-full px-[1.6rem] py-[.8rem] bg-[#F3F9FA] border rounded-[1.2rem] focus:outline-none !overflow-visible ${errors.phoneNumber ? 'border-red-500' : 'border-gray-300'}`}>
                                 <DropdownWithSearch
                                    options={getAllCountryReducer?.data}
                                    onChange={setCountryCode}
                                />
                                <input
                                    type="tel"
                                    placeholder="Phone Number"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    className="bg-[#F3F9FA] roboto-thin  font-[400] border-none focus:ring-0 outline-none w-[70%] "
                                />
                            </div>
                            {errors.phoneNumber && <p className="text-right text-red-500 text-sm">{errors.phoneNumber}</p>}
                        </div>
                        
                        <button
                            type="submit"
                            className="w-full bg-[#0D9B50] roboto-thin  font-[400] text-[2rem] text-white py-3 mb-4 mt-[1rem] lg:mt-[2rem] rounded-[1.2rem] hover:bg-green-700"
                        >
                            Create Account
                        </button>
                    </form>
                    <div className=" flex items-center justify-center w-full">
                        <span className="bg-gray-300 h-px w-full"></span>
                        <span className="px-4 mr-4 text-gray-500 inter-font font-[500]">Or</span>
                        <span className="bg-gray-300 h-px w-full"></span>
                    </div>
                    <div className=" w-full">
                        <button
                            onClick={() => googleLogin()}
                            className="w-full flex justify-center items-center roboto-thin  font-[400] py-[.8rem] my-[1rem] bg-[#F3F9FA]/80 text-[1.3rem] rounded-[1.2rem] hover:bg-[#F3F9FA]">
                            <img src={LoginPageImg.google} alt="Google" className="w-[2.8rem] h-[2.8rem] mr-[0.8rem]" />
                            Sign in with Google
                        </button>
                    </div>
                    <div className="text-center inter-font font-[400] text-[1.6rem] text-[#313957] flex lg:mb-[2rem]">
                        Already have an account? <p onClick={() => {navigate('/login')}} className="text-[#1E4AE9] cursor-pointer">&nbsp;Login</p>
                    </div>
                    <div className="flex justify-center roboto-thin  font-[400] items-center text-[#959CB6] text-center text-[1.4rem] lg:text-[1.6rem] mt-auto">
                        © 2024 ALL RIGHTS RESERVED
                    </div> 
                </div>
            </div>
            <div className='flex flex-col justify-center items-center w-[90%] md:w-[60%] lg:w-[42%] min-h-max lg:h-full'>
                <img src={LoginPageImg.loginlogo} alt="" className="w-full h-full" />
            </div>
        </div>
    );
}

export default Registration;
