import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { sendOtpToCreateUserFailure, sendOtpToCreateUserSuccess } from '../../actions/authActions/sendOtpToCreateUserActions';
import { setSessonToken } from '../../actions/utilsActions/utilsActions';

function* sendOtpToCreateUser({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.sendOtpToCreateUser,
            payload: payload,
            isAuth: false,
        });
        console.log('====================================');
        console.log("SEND_OTP_TO_CREATE_USER_SUCCESS status :", response.data.status);
        console.log("SEND_OTP_TO_CREATE_USER_SUCCESS data :", response.data.response);
        console.log("SEND_OTP_TO_CREATE_USER_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            sendOtpToCreateUserSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        yield put(setSessonToken(response?.data?.response));
    } catch (error) {
        console.log('====================================');
        console.log("SEND_OTP_TO_CREATE_USER_FAILURE status :", error?.response?.status);
        console.log("SEND_OTP_TO_CREATE_USER_FAILURE data:", error?.response?.data);
        console.log("SEND_OTP_TO_CREATE_USER_FAILURE error :", error);
        console.log('====================================');
        yield put(sendOtpToCreateUserFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* sendOtpToCreateUserSaga() {
    yield takeLatest(actionTypes.SEND_OTP_TO_CREATE_USER_REQUEST, sendOtpToCreateUser);
}

export default sendOtpToCreateUserSaga;