import { actionTypes } from "../../types"

const initialState = {
    accessToken: '',
    sessionToken: '',
    userId: '',
    emailForLogin: '',
    emailForForgetPassword: '',
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload
            }
        case actionTypes.SET_USER_ID:
            return {
                ...state,
                userId: action.payload
            }
        case actionTypes.SET_SESSON_TOKEN:
            return {
                ...state,
                sessionToken: action.payload
            }
        case actionTypes.SET_EMAIL_FOR_LOGIN:
            return {
                ...state,
                emailForLogin: action.payload
            }
        case actionTypes.SET_EMAIL_FOR_FORGET_PASSWORD:
            return {
                ...state,
                emailForForgetPassword: action.payload
            }
        default:
            return state;
    }
}
