import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const sendOtpToCreateUser = (payload) => ({
    type: actionTypes.SEND_OTP_TO_CREATE_USER_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const sendOtpToCreateUserSuccess = (payload) => ({
    type: actionTypes.SEND_OTP_TO_CREATE_USER_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const sendOtpToCreateUserFailure = (payload) => ({
    type: actionTypes.SEND_OTP_TO_CREATE_USER_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const sendOtpToCreateUserReset = () => ({
    type: actionTypes.SEND_OTP_TO_CREATE_USER_RESET,
});

// Action creator for resetting all OTP generation reducer
export const sendOtpToCreateUserResetAll = () => ({
    type: actionTypes.SEND_OTP_TO_CREATE_USER_RESET_ALL,
}); 