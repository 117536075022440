import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { sendOtpForForgetPasswordFailure, sendOtpForForgetPasswordSuccess } from '../../actions/authActions/sendOtpForForgetPasswordActions';
import { setSessonToken } from '../../actions/utilsActions/utilsActions';

function* sendOtpForForgetPassword({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.sendOtpForForgetPassword,
            payload: payload,
            isAuth: false,
        });
        console.log('====================================');
        console.log("SEND_OTP_FOR_FORGET_PASSWORD_SUCCESS status :", response.data.status);
        console.log("SEND_OTP_FOR_FORGET_PASSWORD_SUCCESS data :", response.data.response);
        console.log("SEND_OTP_FOR_FORGET_PASSWORD_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            sendOtpForForgetPasswordSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        yield put(setSessonToken(response?.data?.response));
    } catch (error) {
        console.log('====================================');
        console.log("SEND_OTP_FOR_FORGET_PASSWORD_FAILURE status :", error?.response?.status);
        console.log("SEND_OTP_FOR_FORGET_PASSWORD_FAILURE data:", error?.response?.data);
        console.log("SEND_OTP_FOR_FORGET_PASSWORD_FAILURE error :", error);
        console.log('====================================');
        yield put(sendOtpForForgetPasswordFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* sendOtpForForgetPasswordSaga() {
    yield takeLatest(actionTypes.SEND_OTP_FOR_FORGET_PASSWORD_REQUEST, sendOtpForForgetPassword);
}

export default sendOtpForForgetPasswordSaga;