import React from 'react';
import Sidebar from '../../components/main/Sidebar/Sidebar';
import Header from '../../components/main/Header/Header';

import Landing from '../../components/main/Landing/Landing';


function Dashboard() {


    return (

        <div className='w-full h-full'>
            <Header />
            <div className='flex justify-between h-[90%]'>
                <Sidebar />
                <div className='w-[80%]'>
                <Landing />
                </div>
            </div>

        </div>

    );
}

export default Dashboard;
