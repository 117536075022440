import React from 'react';
import Header from '../../components/main/Header/Header';
import BrandSidebar from "../../components/main/BrandSidebar/BrandSidebar";
import BrandLanding from '../../components/main/BrandLanding/BrandLanding';


const DashboardBrand = () => {
  return (
    <div className='w-full h-full'>
        <Header />
        <div className='flex justify-between h-[90%]'>
            <BrandSidebar />
            <div className='w-[80%]'>
            <BrandLanding />
            </div>
        </div>

    </div>
  )
}

export default DashboardBrand;