import React from 'react';
import Sidebar from '../../components/main/Sidebar/Sidebar';
import Header from '../../components/main/Header/Header';
import AccountDetails from '../../components/main/AccountDetails/AccountDetails';


const DashboardLanding = () => {


    return (
        <div className="w-full h-full">
                {/* Header */}
                <Header />
                <div className='flex justify-between h-[90%]'>
                    <Sidebar />
                <div className='w-[80%]'>
                    <AccountDetails />
                </div>
                </div>
        </div>
    )
}

export default DashboardLanding