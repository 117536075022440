import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const googleUserLogin = (payload) => ({
    type: actionTypes.GOOGLE_USER_LOGIN_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const googleUserLoginSuccess = (payload) => ({
    type: actionTypes.GOOGLE_USER_LOGIN_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const googleUserLoginFailure = (payload) => ({
    type: actionTypes.GOOGLE_USER_LOGIN_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const googleUserLoginReset = () => ({
    type: actionTypes.GOOGLE_USER_LOGIN_RESET,
});

// Action creator for resetting all OTP generation reducer
export const googleUserLoginResetAll = () => ({
    type: actionTypes.GOOGLE_USER_LOGIN_RESET_ALL,
}); 