import React from 'react';
import { SidebarImg } from '../../../static/constant/imgConstants';

const Payout = () => {
    return (
        <div className="w-full h-screen bg-[#EDEEF1] overflow-hidden">
            <div className="w-full h-full flex flex-col">
                {/* Header */}
                <header className="w-full flex p-4 justify-between border-b bg-[#EDEEF1]">
                    <div className="text-[1.4rem] text-[#38414A] flex items-center space-x-2 mb-4">
                        <a href="#" className="hover:underline">Settings</a>
                        <span>{'>'}</span>
                        <span className="font-medium">Account Details</span>
                    </div>
                </header>

                {/* Content Area - Apply Scroll */}
                <div className="flex-1 w-full my-[4rem] overflow-scroll">
                    {/* Payout Settings Section */}
                    <div className="flex flex-col w-[65%] p-6 bg-white rounded-[2rem] mx-[2rem]">
                        <div className='w-full flex justify-between'>
                            <h2 className="text-[2rem] text-[#000000] font-semibold">Pay out Settings</h2>
                            <div className='w-[2.1rem] h-[1.9rem]'>
                                <a href="#" className="mt-2">
                                    <img src={SidebarImg.union} alt="Wishlist" className="w-full h-full mr-2" />
                                </a>
                            </div>
                        </div>
                        
                        <div className="w-full">
                            {/* Bank Input Section */}
                        <div className="flex md:w-[75%] items-center my-[3rem] pl-[1rem] md:pl-[2.4rem] space-x-4">
                            <img
                                src={SidebarImg.icici}
                                alt="Bank Logo"
                                className="w-[4rem] h-[4rem] md:w-[4.5rem] md:h-[4.5rem]"
                            />
                            <input
                                type="text"
                                placeholder="ICICI Bank"
                                className="w-full md:w-[42rem] h-[4rem] md:h-[4.5rem] text-[1.6rem] text-[#677489] border border-[#EEEEEE] rounded-[0.9rem] p-2"
                            />
                            <button className="w-full md:w-[12rem] h-[4rem] md:h-[4.5rem] px-6 bg-[#EEEEEE] text-[#202020] text-[1.6rem] rounded-[0.9rem]">
                                Connected
                            </button>
                        </div>

                        {/* Select Bank Section */}
                        <div className="flex justify-between items-center pl-[4rem] md:pl-[8rem] mb-[3rem] space-x-4">
                            <div className="w-full md:w-[72%] flex space-x-4">
                                <input
                                    type="text"
                                    placeholder="Select Bank"
                                    className="w-full md:w-[42rem] h-[4rem] md:h-[4.5rem] text-[1.6rem] text-[#677489] border border-[#EEEEEE] rounded-[0.9rem] p-2"
                                />
                                <button className="w-full md:w-[12rem] h-[4rem] md:h-[4.5rem] px-6 bg-[#EEEEEE] text-[#202020] text-[1.6rem] rounded-[0.9rem]">
                                    Connect
                                </button>
                            </div>
                        </div>
                        </div>
                    </div>

                    {/* Shipping Address Section */}
                    <div className='flex flex-col my-[4rem]'>
                        <div className="flex flex-col w-[90%] md:w-[65%] p-6 justify-between bg-white rounded-[2rem] mx-[2rem]">
                            <div className='w-full flex justify-between'>
                                <h2 className="text-[2rem]  font-semibold text-[#202224]">Shipping Address</h2>
                                <div className=''>
                                    <a href="#" className="mt-2">
                                        <img src={SidebarImg.union} alt="Wishlist" className="w-[2.1rem] h-[1.9rem] mr-2" />
                                    </a>
                                </div>
                            </div>
                            <div className='w-full my-[2rem]'>
                                <div className='w-full mb-[4rem]'>
                                    <div className='w-[80%] flex flex-col'>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-[3rem] mt-4">
                                            <div>
                                                <label className="text-[1.6rem] text-[#111729]">Street</label>
                                                <input type="text" className="text-[1.6rem] mt-1 h-[4rem] p-3 border rounded-[0.5rem] w-full" placeholder="Street Name" />
                                            </div>
                                            <div>
                                                <label className="text-[1.6rem] text-[#111729]">Apt/Suite/ Unit</label>
                                                <input type="text" className="text-[1.6rem] mt-1 h-[4rem] p-3 border rounded-[0.5rem] w-full" placeholder="Optional" />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-[3rem] mt-4">
                                            <div>
                                                <label className="text-[1.6rem] text-[#111729]">Country</label>
                                                <input type="text" className="text-[1.6rem] mt-1 h-[4rem] p-3 border rounded-[0.5rem] w-full" placeholder="Country" />
                                            </div>
                                            <div>
                                                <label className="text-[1.6rem] text-[#111729]">State/ Province</label>
                                                <input type="text" className="text-[1.6rem] mt-1 h-[4rem] p-3 border rounded-[0.5rem] w-full" placeholder="State/ Province" />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-[3rem] mt-4">
                                            <div>
                                                <label className="text-[1.6rem] text-[#111729]">City</label>
                                                <input type="text" className="text-[1.6rem] mt-1 h-[4rem] p-3 border rounded-[0.5rem] w-full" placeholder="City" />
                                            </div>
                                            <div>
                                                <label className="text-[1.6rem] text-[#111729]">Post Code</label>
                                                <input type="text" className="text-[1.6rem] mt-1 h-[4rem] p-3 border rounded-[0.5rem] w-full" placeholder="Optional" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Payout History Button */}
                    <div className='w-[90%] md:w-[65%] flex justify-end mb-[4rem]'>
                        <div className='w-[28.6rem] h-[4.5rem]'>
                            <button className="p-4 bg-[#0D9B50] text-[1.6rem] text-white rounded-[1rem] w-full h-full text-center">
                                Payout History
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Payout;
