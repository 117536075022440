import React, { useState, useEffect, useRef } from "react";

const DropdownWithSearch = ({ options, placeholder, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(options[0]); // Select first option by default
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef(null);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Automatically select the first option if none is selected
    useEffect(() => {
        if (options.length && !selectedOption) {
            setSelectedOption(options[0]); // Default to the first option
            onChange(options[0]); // Default to the first option
        }
    }, [options, selectedOption]);

    const handleSelect = (option) => {
        setSelectedOption(option);
        onChange(option); // Return the full selected object
        setIsOpen(false);
    };

    // Filter options based on the search term
    const filteredOptions = options.filter((option) =>
        (option.countryCode + " " + option.phoneCode)
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
    );

    return (
        <div className="relative text-[1.6rem] w-[7rem] it !overflow-visible" ref={dropdownRef}>
            <div
                className="flex items-center cursor-pointer bg-transparent !overflow-visible"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="w-[5rem] text-gray-700">
                    {selectedOption
                        ? `${selectedOption.countryCode} ${selectedOption.phoneCode}`
                        : placeholder || "+91"}
                </span>
                <span className="">
                    <svg
                        className={`w-[1.2rem] h-[1.2rem] transition-transform ${
                            isOpen ? "rotate-180" : ""
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>
                </span>
            </div>
            {isOpen && (
                <div className="absolute z-10 !overflow-visible mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg">
                    <div className="p-2 !overflow-visible">
                        <input
                            type="text"
                            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-400 !overflow-visible"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <ul className="max-h-[30rem] overflow-auto">
                        {filteredOptions.length > 0 ? (
                            filteredOptions.map((option, index) => (
                                <li
                                    key={index}
                                    className={`p-2 cursor-pointer hover:bg-gray-100 ${
                                        selectedOption === option
                                            ? "font-semibold text-teal-500"
                                            : ""
                                    }`}
                                    onClick={() => handleSelect(option)}
                                >
                                    {`${option.countryCode} ${option.phoneCode}`}
                                </li>
                            ))
                        ) : (
                            <li className="p-2 text-[1.2rem] text-gray-500">
                                No options found
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default DropdownWithSearch;
