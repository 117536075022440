import React from 'react';
import { FiFilter, FiMoreVertical, FiSearch } from 'react-icons/fi';
import { SidebarImg } from '../../../static/constant/imgConstants';

const MyCampaigns = () => {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const campaigns = [
        {
            name: 'Name of the campaign',
            period: '12.09.2024 - 12.09.2024',
            payment: '$100',
            type: 'Custom',
            status: 'Active',
        },
        {
            name: 'Name of the campaign',
            period: '12.09.2024 - 12.09.2024',
            payment: '$100',
            type: 'Custom',
            status: 'Draft',
        },
        {
            name: 'Name of the campaign',
            period: '12.09.2024 - 12.09.2024',
            payment: '$100',
            type: 'Custom',
            status: 'Rejected',
        },
    ];

    const getStatusStyle = (status) => {
        switch (status) {
            case 'Active':
                return 'bg-[#00B69B] text-[#FFFFFF]';
            case 'Draft':
                return 'bg-[#FCBE2D] text-[#FFFFFF]';
            case 'Rejected':
                return 'bg-[#FD5454] text-[#FFFFFF]';
            default:
                return '';
        }
    };

    return (
        <div className="w-full h-full flex flex-col justify-start bg-[#EDEEF1] p-4">
            <div className="w-full h-full flex flex-col px-[1rem]">
                {/* Header */}
                <header className="w-full flex p-4 justify-between border-b bg-[#EDEEF1]">
                    <div className="text-[1.4rem] text-[#38414A] flex items-center space-x-2 mb-4">
                        <a href="#" className="hover:underline inter-font font-[500]">Home</a>
                        <span>{'>'}</span>
                        <span className="inter-font font-[500]">My Campaigns</span>
                    </div>
                </header>

                <div className='w-full flex justify-between items-center my-[4rem] px-[1rem]'>
                    <div className="relative">
                        <FiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 w-[2.1rem] h-[2.1rem] text-gray-400" />
                        <input
                            type="text"
                            placeholder="Search campaigns"
                            className="w-[20rem] p-3 border text-[1.4rem] inter-font font-[400] border-gray-300 rounded-[1rem] pl-16 outline-none"
                        />
                    </div>

                    <div className='flex space-x-2'>
                        <button className="bg-white p-4 rounded-[1rem] outline-none">
                            <FiFilter className="inline-block w-[2.1rem] h-[2.1rem]" />
                        </button>
                        <button className="flex items-center bg-[#0D9B50] text-[1.6rem] text-white px-4 py-2 rounded-[1rem] outline-none">
                            <img src={SidebarImg.campaigns1} alt="Campaign Icon" className="w-[2.1rem] h-[2.1rem]" />
                            + <h1 className='inter-font font-[500] px-4'>New Campaigns</h1>
                        </button>
                    </div>
                </div>
                <div className='w-full flex flex-col p-[1rem] justify-between bg-white rounded-[2.6rem] px-[2rem] bg-[#ffffff] '>
                    <div className='flex justify-between p-4'>
                        <h1 className="text-[2.4rem] text-[#202224] inter-font font-[700]">Campaigns</h1>
                        <select className="w-[15rem] border border-gray-300 text-[1.4rem] text-[#2B303466] inter-font font-[500] p-2 rounded-[1rem] outline-none">
                            {months.map((month, index) => (
                                <option key={index} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* Table Section */}
                    <div className="overflow-x-auto px-[2rem] mb-[4rem] scrollbar-thin-custom">
                        <table className="min-w-full bg-white">
                            <thead>
                                <tr className="border-b bg-[#F1F4F9] text-[1.4rem] text-[#202224] inter-font font-[700]">
                                    <th className="p-4 text-left">Campaign</th>
                                    <th className="p-4 text-left">Period</th>
                                    <th className="p-4 text-left">Payment</th>
                                    <th className="p-4 text-left">Type</th>
                                    <th className="p-4 text-left">Status</th>
                                    <th className="p-4 text-left">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {campaigns.map((campaign, index) => (
                                    <tr key={index} className="border-b text-[1.4rem] text-[#202224] inter-font font-[600]">
                                        <td className="p-4">{campaign.name}</td>
                                        <td className="p-4">{campaign.period}</td>
                                        <td className="p-4">{campaign.payment}</td>
                                        <td className="p-4">{campaign.type}</td>
                                        <td className="p-4">
                                            <span
                                                className={`inline-block px-3 py-1 w-[9.3rem] h-[2.7rem] text-center rounded-full text-[1.4rem] inter-font font-[700] ${getStatusStyle(
                                                    campaign.status
                                                )}`}
                                            >
                                                {campaign.status}
                                            </span>
                                        </td>
                                        <td className="p-4">
                                            <FiMoreVertical className="cursor-pointer" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='flex mt-auto justify-center'>
                    <div className="flex justify-center items-center text-[#959CB6] mt-auto mx-auto text-center text-[1.6rem]">
                        © 2024 ALL RIGHTS RESERVED
                    </div>
                    <div className="text-[1.4rem] text-[#8A98AC] flex justify-center space-x-2 gap-2 mr-8">
                        <a href="#" className="hover:underline">Privacy Policy</a>
                        <a href="#" className="hover:underline">Cookies</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyCampaigns;
