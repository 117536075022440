import React from 'react';
import Sidebar from '../../components/main/Sidebar/Sidebar';
import Header from '../../components/main/Header/Header';
import Footer from '../../components/main/Footer/Footer';
import Payout from '../../components/main/Payout/Payout';

function PayoutDashboard() {

    return (
        <div className="flex flex-col h-screen">
            {/* Header */}
            <Header />
        <div className='flex justify-between h-[90%]'>  
            <Sidebar />
            <div className='w-[80%]'>
            <Payout />
            </div>
        </div>
    </div>
    );
}

export default PayoutDashboard;
