export const actionTypes = {
	// Utils
	USER_LOGOUT: 'USER_LOGOUT',
	SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
	SET_SESSON_TOKEN: 'SET_SESSON_TOKEN',
	SET_EMAIL_FOR_LOGIN: 'SET_EMAIL_FOR_LOGIN',
	SET_EMAIL_FOR_FORGET_PASSWORD: 'SET_EMAIL_FOR_FORGET_PASSWORD',
	SET_USER_ID: 'SET_USER_ID',

	// Public
	GET_ALL_COUNTRAY_REQUEST: 'GET_ALL_COUNTRAY_REQUEST',
	GET_ALL_COUNTRAY_SUCCESS: 'GET_ALL_COUNTRAY_SUCCESS',
	GET_ALL_COUNTRAY_FAILURE: 'GET_ALL_COUNTRAY_FAILURE',
	GET_ALL_COUNTRAY_RESET: 'GET_ALL_COUNTRAY_RESET',
	GET_ALL_COUNTRAY_RESET_ALL: 'GET_ALL_COUNTRAY_RESET_ALL',

	// Auth 
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
	LOGIN_RESET: 'LOGIN_RESET',
	LOGIN_RESET_ALL: 'LOGIN_RESET_ALL',

	GOOGLE_USER_LOGIN_REQUEST: 'GOOGLE_USER_LOGIN_REQUEST',
	GOOGLE_USER_LOGIN_SUCCESS: 'GOOGLE_USER_LOGIN_SUCCESS',
	GOOGLE_USER_LOGIN_FAILURE: 'GOOGLE_USER_LOGIN_FAILURE',
	GOOGLE_USER_LOGIN_RESET: 'GOOGLE_USER_LOGIN_RESET',
	GOOGLE_USER_LOGIN_RESET_ALL: 'GOOGLE_USER_LOGIN_RESET_ALL',

	GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
	GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
	GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',
	GET_USER_INFO_RESET: 'GET_USER_INFO_RESET',
	GET_USER_INFO_RESET_ALL: 'GET_USER_INFO_RESET_ALL',

	CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
	CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
	CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
	CREATE_USER_RESET: 'CREATE_USER_RESET',
	CREATE_USER_RESET_ALL: 'CREATE_USER_RESET_ALL',

	VERIFY_OTP_AND_CREATE_USER_REQUEST: 'VERIFY_OTP_AND_CREATE_USER_REQUEST',
	VERIFY_OTP_AND_CREATE_USER_SUCCESS: 'VERIFY_OTP_AND_CREATE_USER_SUCCESS',
	VERIFY_OTP_AND_CREATE_USER_FAILURE: 'VERIFY_OTP_AND_CREATE_USER_FAILURE',
	VERIFY_OTP_AND_CREATE_USER_RESET: 'VERIFY_OTP_AND_CREATE_USER_RESET',
	VERIFY_OTP_AND_CREATE_USER_RESET_ALL: 'VERIFY_OTP_AND_CREATE_USER_RESET_ALL',

	SEND_OTP_FOR_FORGET_PASSWORD_REQUEST: 'SEND_OTP_FOR_FORGET_PASSWORD_REQUEST',
	SEND_OTP_FOR_FORGET_PASSWORD_SUCCESS: 'SEND_OTP_FOR_FORGET_PASSWORD_SUCCESS',
	SEND_OTP_FOR_FORGET_PASSWORD_FAILURE: 'SEND_OTP_FOR_FORGET_PASSWORD_FAILURE',
	SEND_OTP_FOR_FORGET_PASSWORD_RESET: 'SEND_OTP_FOR_FORGET_PASSWORD_RESET',
	SEND_OTP_FOR_FORGET_PASSWORD_RESET_ALL: 'SEND_OTP_FOR_FORGET_PASSWORD_RESET_ALL',

	VERIFY_OTP_FOR_FORGET_PASSWORD_REQUEST: 'VERIFY_OTP_FOR_FORGET_PASSWORD_REQUEST',
	VERIFY_OTP_FOR_FORGET_PASSWORD_SUCCESS: 'VERIFY_OTP_FOR_FORGET_PASSWORD_SUCCESS',
	VERIFY_OTP_FOR_FORGET_PASSWORD_FAILURE: 'VERIFY_OTP_FOR_FORGET_PASSWORD_FAILURE',
	VERIFY_OTP_FOR_FORGET_PASSWORD_RESET: 'VERIFY_OTP_FOR_FORGET_PASSWORD_RESET',
	VERIFY_OTP_FOR_FORGET_PASSWORD_RESET_ALL: 'VERIFY_OTP_FOR_FORGET_PASSWORD_RESET_ALL',

	FORGET_PASSWORD_RESET_REQUEST: 'FORGET_PASSWORD_RESET_REQUEST',
	FORGET_PASSWORD_RESET_SUCCESS: 'FORGET_PASSWORD_RESET_SUCCESS',
	FORGET_PASSWORD_RESET_FAILURE: 'FORGET_PASSWORD_RESET_FAILURE',
	FORGET_PASSWORD_RESET_RESET: 'FORGET_PASSWORD_RESET_RESET',
	FORGET_PASSWORD_RESET_RESET_ALL: 'FORGET_PASSWORD_RESET_RESET_ALL',

	
	SEND_OTP_TO_CREATE_USER_REQUEST: 'SEND_OTP_TO_CREATE_USER_REQUEST',
	SEND_OTP_TO_CREATE_USER_SUCCESS: 'SEND_OTP_TO_CREATE_USER_SUCCESS',
	SEND_OTP_TO_CREATE_USER_FAILURE: 'SEND_OTP_TO_CREATE_USER_FAILURE',
	SEND_OTP_TO_CREATE_USER_RESET: 'SEND_OTP_TO_CREATE_USER_RESET',
	SEND_OTP_TO_CREATE_USER_RESET_ALL: 'SEND_OTP_TO_CREATE_USER_RESET_ALL',

	USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
	USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
	USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
	USER_LOGIN_RESET: 'USER_LOGIN_RESET',
	USER_LOGIN_RESET_ALL: 'USER_LOGIN_RESET_ALL',

	VERIFY_OTP_FOR_LOGIN_REQUEST: 'VERIFY_OTP_FOR_LOGIN_REQUEST',
	VERIFY_OTP_FOR_LOGIN_SUCCESS: 'VERIFY_OTP_FOR_LOGIN_SUCCESS',
	VERIFY_OTP_FOR_LOGIN_FAILURE: 'VERIFY_OTP_FOR_LOGIN_FAILURE',
	VERIFY_OTP_FOR_LOGIN_RESET: 'VERIFY_OTP_FOR_LOGIN_RESET',
	VERIFY_OTP_FOR_LOGIN_RESET_ALL: 'VERIFY_OTP_FOR_LOGIN_RESET_ALL',

}

