import React, { useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/utility/PrivateRoute';
import Login from './hoc/Login/Login';
import Dashboard from './hoc/Dashboard/Dashboard';
import DashboardSocialAccount from './hoc/DashboardSocialAccount/DashboardSocialAccount';
import PayoutDashboard from './hoc/PayoutDashboard/PayoutDashboard';
import DashboardLanding from './hoc/DashboardLanding/DashboardLanding';
import DashboardShippingAddress from './hoc/DashboardShippingAddress/DashboardShippingAddress';
import Registration from './hoc/Registration/Registration';
import ForgotPassword from './hoc/ForgotPassword/ForgotPassword';
import ResetPassword from './hoc/ResetPassword/ResetPassword';
import Verify from './hoc/Verify/Verify';
import store, { persistor } from './store/configStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastProvider } from './hooks/useToast';
import BrandSidebar from './components/main/BrandSidebar/BrandSidebar';
import './App.css';
import VerifyEmailOtp from './hoc/Verify/VerifyEmailOtp';
import VerifyPopup from './components/main/VerifyPopup/VerifyPopup';
import DashboardBrand from './hoc/DashboardBrand/DashboardBrand';
import BrandLanding from './components/main/BrandLanding/BrandLanding';
import MyCampaigns from './components/main/MyCampaigns/MyCampaigns';
import BasicDetails from './components/main/MyCampaigns/BasicDetails';

function App() {
  const state = store.getState();
  const AccessToken = state.utilReducers?.accessToken

  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className="App">
          {/* Wrap the application with GoogleOAuthProvider */}
          <GoogleOAuthProvider clientId="951516113922-aen49fhaed94ou3v2afobrdk47ns3304.apps.googleusercontent.com">
            <ToastProvider defaultPosition="top-center" defaultDuration={5000}>
              {/* <h1 className="text-3xl font-bold underline text-[#f0f]"> Hello world! </h1> */}
              {/* <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} /> */}
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:userType" element={<ResetPassword />} />
                <Route path="/verifyOtp" element={<Verify />} />
                <Route path="/verifyEmailOtp/:userType" element={<VerifyEmailOtp />} />
                {/* Wrap protected routes in PrivateRoute */}
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/dashboard-social-account" element={<PrivateRoute><DashboardSocialAccount /></PrivateRoute>} />
                <Route path="/pay-out-dashboard" element={<PrivateRoute><PayoutDashboard /></PrivateRoute>} />
                <Route path="/dashboard-landing" element={<PrivateRoute><DashboardLanding/></PrivateRoute>} />
                <Route path="/dashboard-shipping-address" element={<PrivateRoute><DashboardShippingAddress/></PrivateRoute>} />
                <Route path="/verify-popup" element={<PrivateRoute><VerifyPopup /></PrivateRoute>} />
                <Route path="/brand-sidebar" element={<PrivateRoute><BrandSidebar /></PrivateRoute>} />
                <Route path="/dashboard-brand" element={<PrivateRoute><DashboardBrand /></PrivateRoute>} />
                <Route path="/brand-landing" element={<PrivateRoute><BrandLanding /></PrivateRoute>} />
                <Route path="/my-campaigns" element={<MyCampaigns />} />
                <Route path="/basic-details" element={<BasicDetails />} />
              </Routes>
            </ToastProvider>
          </GoogleOAuthProvider>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
