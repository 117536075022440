import { all } from "redux-saga/effects";
import loginSaga from "./authSagas/loginSaga";
import getUserInfoSaga from "./authSagas/getUserInfoSaga";
import createUserSaga from "./authSagas/createUserSaga";
import verifyOtpAndCreateUserSaga from "./authSagas/verifyOtpAndCreateUserSaga";
import sendOtpForForgetPasswordSaga from "./authSagas/sendOtpForForgetPasswordSaga";
import verifyOtpForForgetPasswordSaga from "./authSagas/verifyOtpForForgetPasswordSaga";
import forgetPasswordResetSaga from "./authSagas/forgetPasswordResetSaga";
import getAllCountrySaga from "./publicSagas/getAllCountrySaga";
import googleUserLoginSaga from "./authSagas/googleUserLoginSaga";
import sendOtpToCreateUserSaga from "./authSagas/sendOtpToCreateUserSaga";
import userLoginSaga from "./authSagas/userLoginSaga";
import verifyOtpForLoginSaga from "./authSagas/verifyOtpForLoginSaga";


export default function* rootSaga() {
    yield all([
        // public sagas
        getAllCountrySaga(),

        // auth sagas
        loginSaga(),
        googleUserLoginSaga(),
        getUserInfoSaga(),
        createUserSaga(),
        verifyOtpAndCreateUserSaga(),
        sendOtpForForgetPasswordSaga(),
        verifyOtpForForgetPasswordSaga(),
        forgetPasswordResetSaga(),
        sendOtpToCreateUserSaga(),
        userLoginSaga(),
        verifyOtpForLoginSaga(),
    ])
}