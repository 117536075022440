import React from 'react'
import { SidebarImg } from '../../../static/constant/imgConstants';


const ShippingAddress = () => {
    return (
        <div className="w-full h-full bg-[#EDEEF1]">
            <div className='w-full flex flex-col'>
                <header className="w-full flex p-4 justify-between border-b bg-[#EDEEF1]">
                    <div className="text-[1.4rem] text-[#38414A] flex items-center space-x-2 mb-4">
                        <a href="#" className="hover:underline">Settings</a>
                        <span>{'>'}</span>
                        <span className="font-medium">Account Details</span>
                    </div>
                </header>

            {/* Account Details Content */}
            <div className='w-full flex flex-col my-[4rem]'>
                <div className="flex flex-col w-[65%] p-6 justify-between bg-white rounded-[2rem] mx-[2rem]">
                    <div className='w-full flex justify-between'>
                        <h2 className="text-[2rem]  font-semibold text-[#202224]">Shipping Address</h2>
                        <div className=''>
                            <a href="#" className="mt-2">
                                <img src={SidebarImg.union} alt="Wishlist" className="w-[2.1rem] h-[1.9rem] mr-2" />
                            </a>
                        </div>
                    </div>
                    <div className='w-full my-[2rem]'>
                        <div className='w-full mb-[4rem]'>
                            <div className='w-[80%] flex flex-col'>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-[3rem] mt-4">
                                    <div>
                                        <label className="text-[1.6rem] text-[#111729]">Street</label>
                                        <input type="text" className="mt-1 text-[1.6rem] h-[4rem] p-3 border rounded-[0.5rem] w-full" placeholder="Street Name" />
                                    </div>
                                    <div>
                                        <label className="text-[1.6rem] text-[#111729]">Apt/Suite/ Unit</label>
                                        <input type="text" className="mt-1 text-[1.6rem] h-[4rem] p-3 border rounded-[0.5rem] w-full" placeholder="Optional" />
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-[3rem] mt-4">
                                    <div>
                                        <label className="text-[1.6rem] text-[#111729]">Country</label>
                                        <input type="email" className="mt-1  text-[1.6rem] h-[4rem] p-3 border rounded-[0.5rem] w-full" placeholder="Country" />
                                    </div>
                                    <div>
                                        <label className="text-[1.6rem] text-[#111729]">State/ Province</label>
                                        <input type="email" className="mt-1 text-[1.6rem] h-[4rem] p-3 border rounded-[0.5rem] w-full" placeholder="State/ Province" />
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-[3rem] mt-4">
                                    <div>
                                        <label className="text-[1.6rem] text-[#111729]">City</label>
                                        <input type="text" className="mt-1 text-[1.6rem] h-[4rem] p-3 border rounded-[0.5rem] w-full" placeholder="City" />
                                    </div>
                                    <div>
                                        <label className="text-[1.6rem] text-[#111729]">Post Code</label>
                                        <input type="text" className="mt-1 text-[1.6rem] h-[4rem] p-3 border rounded-[0.5rem] w-full" placeholder="Optional" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default ShippingAddress