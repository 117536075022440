import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LoginPageImg } from '../../static/constant/imgConstants';

function Success() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };

    return (
        <div className='flex w-full h-[100vh] overflow-hidden'>
            <div className="flex-col w-full md:w-[55%] lg:w-[65%] justify-center p-6 overflow-hidden">
                {/* Left Side: Content Section */}
                <div className="w-[8rem]">
                    <div className="flex justify-start">
                        <div className="w-[8rem] h-[5.6rem]">
                            <img className="w-full h-full" alt="" src={LoginPageImg.login1} />
                        </div>
                    </div>
                </div>

                <div className='w-[48%] container-lg  justify-center  flex flex-col '>
                    <h2 className="text-[1.6rem] text-start  md:text-[1.8rem] font-semibold mb-3 md:mb-4">
                        Reset Password
                    </h2>
                    <p className="text-[1.4rem] text-start  md:text-[1.6rem] text-[#313957] mb-4">
                        Set the new password for your account so you can login and access all featuress.
                    </p>

                    <div className='w-[8rem] h-[8rem] mx-auto mt-[8rem]'>
                        <img
                            className="w-full h-full"
                            alt="Success"
                            src={LoginPageImg.successfully}
                        />
                    </div>

                    <h2 className="text-[1.6rem] text-center text-[#0D9B50] md:text-[1.8rem] font-semibold mt-8 mb-4">
                        Password Updated successfully!
                    </h2>
                    <button type="submit"
                        className="bg-green-500 text-white text-[1.6rem] md:text-[2rem] mt-[8rem] py-3 rounded-[1.2rem] w-full font-semibold">
                        Continue
                    </button>
                </div>

                <div className="justify-center items-center py-4 text-[#959CB6] mt-auto overflow-hidden">
                    <p className="text-center text-[1.6rem]">© 2024 ALL RIGHTS RESERVED</p>
                </div>
            </div>

            {/* Right Side: Image Section */}
            <div className="w-[0%] md:w-[45%] lg:w-[35%] mt-8 lg:mt-0 overflow-hidden">
                <Slider {...settings}>
                    <div>
                        <img className="w-full h-full object-cover" alt="Success visual 1" src={LoginPageImg.loginlogo} />
                    </div>
                    <div>
                        <img className="w-full h-full object-cover" alt="Success visual 2" src={LoginPageImg.loginlogo} />
                    </div>
                    <div>
                        <img className="w-full h-full object-cover" alt="Success visual 3" src={LoginPageImg.loginlogo} />
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default Success;
