import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { googleUserLoginFailure, googleUserLoginSuccess } from '../../actions/authActions/googleUserLoginActions';
import { setAccessToken } from '../../actions/utilsActions/utilsActions';

function* googleUserLogin({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.googleUserLogin,
            payload: payload,
            isAuth: false,
        });
        console.log('====================================');
        console.log("GOOGLE_USER_LOGIN_SUCCESS status :", response.data.status);
        console.log("GOOGLE_USER_LOGIN_SUCCESS data :", response.data.response);
        console.log("GOOGLE_USER_LOGIN_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(
            googleUserLoginSuccess({
                status: response?.data?.status,
                response: response?.data?.response,
                message: response?.data?.message
            })
        );
        yield put(setAccessToken(response?.data?.response?.accessToken));
    } catch (error) {
        console.log('====================================');
        console.log("GOOGLE_USER_LOGIN_FAILURE status :", error?.response?.status);
        console.log("GOOGLE_USER_LOGIN_FAILURE data:", error?.response?.data);
        console.log("GOOGLE_USER_LOGIN_FAILURE error :", error);
        console.log('====================================');
        yield put(googleUserLoginFailure({
            status: error?.response?.data?.status,
            response: {},
            message: error?.response?.data?.message
        }));
    }
}

function* googleUserLoginSaga() {
    yield takeLatest(actionTypes.GOOGLE_USER_LOGIN_REQUEST, googleUserLogin);
}

export default googleUserLoginSaga;