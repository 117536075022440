import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const sendOtpForForgetPassword = (payload) => ({
    type: actionTypes.SEND_OTP_FOR_FORGET_PASSWORD_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const sendOtpForForgetPasswordSuccess = (payload) => ({
    type: actionTypes.SEND_OTP_FOR_FORGET_PASSWORD_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const sendOtpForForgetPasswordFailure = (payload) => ({
    type: actionTypes.SEND_OTP_FOR_FORGET_PASSWORD_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const sendOtpForForgetPasswordReset = () => ({
    type: actionTypes.SEND_OTP_FOR_FORGET_PASSWORD_RESET,
});

// Action creator for resetting all OTP generation reducer
export const sendOtpForForgetPasswordResetAll = () => ({
    type: actionTypes.SEND_OTP_FOR_FORGET_PASSWORD_RESET_ALL,
}); 