import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import utilReducers from "./utilReducers/utilReducers";
import loginReducer from "./authReducers/loginReducer";
import getUserInfoReducer from "./authReducers/getUserInfoReducer";
import createUserReducer from "./authReducers/createUserReducer";
import verifyOtpAndCreateUserReducer from "./authReducers/verifyOtpAndCreateUserReducer";
import sendOtpForForgetPasswordReducer from "./authReducers/sendOtpForForgetPasswordReducer";
import verifyOtpForForgetPasswordReducer from "./authReducers/verifyOtpForForgetPasswordReducer";
import forgetPasswordResetReducer from "./authReducers/forgetPasswordResetReducer";
import getAllCountryReducer from "./publicReducers/getAllCountryReducer";
import googleUserLoginReducer from "./authReducers/googleUserLoginReducer";
import sendOtpToCreateUserReducer from "./authReducers/sendOtpToCreateUserReducer";
import userLoginReducer from "./authReducers/userLoginReducer";
import verifyOtpForLoginReducer from "./authReducers/verifyOtpForLoginReducer";
import logoutReducer from "./authReducers/logoutReducer";


const persistConfig = {
	key: 'root',
	storage,
	stateReconciler: autoMergeLevel2,
	whitelist: [
		// add reducers to persist data
		'utilReducers',
		'getUserInfoReducer',
	],
};

const appReducer = combineReducers({
	utilReducers: utilReducers,

	// public rducers
	getAllCountryReducer: getAllCountryReducer, 

	// auth reducers
	loginReducer: loginReducer,
	googleUserLoginReducer: googleUserLoginReducer,
	getUserInfoReducer: getUserInfoReducer,
	createUserReducer: createUserReducer,
	verifyOtpAndCreateUserReducer: verifyOtpAndCreateUserReducer,
	sendOtpForForgetPasswordReducer: sendOtpForForgetPasswordReducer,
	verifyOtpForForgetPasswordReducer: verifyOtpForForgetPasswordReducer,
	forgetPasswordResetReducer: forgetPasswordResetReducer,
	sendOtpToCreateUserReducer: sendOtpToCreateUserReducer,
	userLoginReducer: userLoginReducer,
	verifyOtpForLoginReducer: verifyOtpForLoginReducer,
	logoutReducer: logoutReducer,
})

const rootReducers = (state, action) => {
	if (action.type === 'USER_LOGOUT') {
		console.log("logggginggg ouutttt")
		return appReducer(undefined, action)
	}

	return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducers);
