import React from 'react';
import { GoArrowLeft } from "react-icons/go";
import { FiTrendingUp, FiTrendingDown } from 'react-icons/fi';
import SummaryCard from './SummaryCard';
import CampaignCard from './CampaignCard';
import MediaSource from './MediaSource';
import CampaignTable from './CampaignTable';
import { BarndImg } from '../../../static/constant/imgConstants';

const BrandLanding = () => {

    const summaryData = [
        { title: 'Campaigns', value: 10, percentage: '+ 1.3%', trend: <FiTrendingUp />, imgSrc: BarndImg.campaign },
        { title: 'Activated', value: 2, percentage: '+ 1.3%', trend: <FiTrendingUp />, imgSrc: BarndImg.impression },
        { title: 'Completed', value: 10, percentage: '- 4.3%', trend: <FiTrendingDown />, imgSrc: BarndImg.views },
        { title: 'Cancelled', value: 3, percentage: '- 4.3%', trend: <FiTrendingDown />, imgSrc: BarndImg.cancelled }
    ];

    // Campaign Card Data (Delicious Grounds)
    const campaignData = [
        { title: 'Delicious Grounds', daysLeft: 15, progress: 74, imgSrc: BarndImg.delicious1 },
        { title: 'Delicious Grounds', daysLeft: 15, progress: 74, imgSrc: BarndImg.delicious2 }
    ];

    // Media Sources Data
    const mediaSources = [
        { name: 'Google', progress: 70, imgSrc: BarndImg.google0 },
        { name: 'Facebook', progress: 70, imgSrc: BarndImg.facebook0 },
        { name: 'LinkedIn', progress: 58, imgSrc: BarndImg.linkedin1 },
        { name: 'YouTube', progress: 70, imgSrc: BarndImg.youtube }
    ];

    return (
        <div className='w-full h-full bg-[#EDEEF1] overflow-scroll'>
            <div className='w-full flex flex-col justify-center'>
                <div className="flex justify-start items-center border-b bg-[#EDEEF1] mx-[2rem] mt-4 p-4">
                    <div className="text-[1.4rem] text-[#38414A] flex items-center space-x-2 mb-2">
                        <a href="#" className=""><GoArrowLeft size={18} color='#BCBCBC' /></a>
                        <span className="text-[1.8rem] text-[#38414A] inter-font font-[500]">Control Tower Dashboard</span>
                    </div>
                </div>
                <h2 className='text-[2.8rem] text-[#7F8387] px-[4rem] inter-font font-[500] mt-[3rem]'>Good Morning Laura !</h2>
                <h2 className='text-[1.8rem] text-[#7F8387] px-[4rem] inter-font font-[500]'>Welcome to creator dashboard  lets collaborate!</h2>

                <div className="p-8 mt-[1rem]">
                    {/* Summary Cards */}
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
                        {summaryData.map((data, index) => (
                <SummaryCard
                    key={index}
                    title={data.title}
                    value={data.value}
                    percentage={data.percentage}
                    trend={data.trend}
                    imgSrc={data.imgSrc}
                />
            ))}
                    </div>

                    <h2 className='text-[2.4rem] text-[#202224] px-[3rem] inter-font font-[700] mt-[6.8rem] mb-[2rem]'>Recent Campaigns</h2>

                    {/* Delicious Grounds Cards and Media Sources in a Row */}
                    <div className="flex flex-col md:flex-row gap-4 mb-8">
                        {/* Two Delicious Grounds Campaign Cards */}
                        <div className="w-full md:w-[50%] flex gap-4">
                            {campaignData.map((campaign, index) => (
                                <CampaignCard key={index} {...campaign} className="w-full" />
                            ))}
                        </div>

                        {/* Media Sources */}
                        <div className="w-full md:w-[50%] bg-white p-[2rem] rounded-[1.4rem]">
                            <h4 className="text-[1.8rem] inter-font font-[600] my-[2rem]  ">By Media Sources</h4>
                            {mediaSources.map((source, index) => (
                                <MediaSource key={index} {...source} />
                            ))}
                        </div>
                    </div>

                    <CampaignTable  />
                </div>
            </div>
        </div>
    )
}

export default BrandLanding