import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const getAllCountry = (payload) => ({
    type: actionTypes.GET_ALL_COUNTRAY_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getAllCountrySuccess = (payload) => ({
    type: actionTypes.GET_ALL_COUNTRAY_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getAllCountryFailure = (payload) => ({
    type: actionTypes.GET_ALL_COUNTRAY_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getAllCountryReset = () => ({
    type: actionTypes.GET_ALL_COUNTRAY_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getAllCountryResetAll = () => ({
    type: actionTypes.GET_ALL_COUNTRAY_RESET_ALL,
}); 