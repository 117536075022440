import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { SidebarImg } from '../../../static/constant/imgConstants';
import { logout } from '../../../store/actions/utilsActions/utilsActions';

const BrandSidebar = () => {
    const [selected, setSelected] = useState('Dashboard');
    const [hovered, setHovered] = useState(null); // Track which item is being hovered
    const dispatch = useDispatch();

    // Define the menu items
    const menuItems = [
        {
            name: 'Dashboard',
            normalImg: require('../../../static/img/Monitorb.png'),
            hoverImg: require('../../../static/img/Monitorw.png'),
        },
        {
            name: 'Reports',
            normalImg: require('../../../static/img/Reportb.png'),
            hoverImg: require('../../../static/img/Reportw.png'),
        },
        {
            name: 'Campaigns',
            normalImg: require('../../../static/img/Groupb.png'),
            hoverImg: require('../../../static/img/Groupw.png'),
        },
        {
            name: 'Audiences',
            normalImg: require('../../../static/img/Focusb.png'),
            hoverImg: require('../../../static/img/Focusw.png'),
        },
        {
            name: 'Accounts',
            normalImg: require('../../../static/img/Bankb.png'),
            hoverImg: require('../../../static/img/Bankw.png'),
        },
        {
            name: 'Users',
            normalImg: require('../../../static/img/Manb.png'),
            hoverImg: require('../../../static/img/Manw.png'),
        },
    ];

    return (
        <div className="w-[45%] sm:w-[25%] md:w-[20%] h-full flex flex-col px-[1rem]">
            {/* Sidebar */}
            <div className='w-full h-full'>
                <div className="bg-white w-full flex flex-col items-start p-4 space-y-2">
                    {menuItems.map((item) => (
                        <div
                            key={item.name}
                            className={`flex items-center space-x-6 p-4 w-full rounded-lg cursor-pointer transition-colors duration-200
                        ${selected === item.name ? 'bg-[#0D9B50] text-white' : 'text-black'
                                }
                        ${hovered === item.name && selected !== item.name ? 'bg-[#0D9B50] text-white' : ''}`}
                            onClick={() => setSelected(item.name)}
                            onMouseEnter={() => setHovered(item.name)}
                            onMouseLeave={() => setHovered(null)}>
                            {/* Display hover image if selected or hovered, otherwise normal image */}
                            <div className='flex w-[2.4rem] h-[2.4rem]'>
                                <img
                                    src={
                                        selected === item.name || hovered === item.name
                                            ? item.hoverImg
                                            : item.normalImg
                                    }
                                    alt={`${item.name} icon`}
                                    className="w-full h-full pl-2"
                                />
                            </div>
                            <span className="text-[1.8rem] inter-font font-[400]">{item.name}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className='p-4 mt-auto left-2'>
                <button 
                    className="w-[20rem] text-[1.8rem] inter-font font-[400] text-[#505050] hover:text-[#ffffff] rounded-[1.5rem] flex items-center p-4 gap-4 hover:bg-green-700" 
                    onClick={() => { dispatch(logout()) }}
                >
                    <img src={SidebarImg.logoutimg} alt="Logout" className="w-[2.1rem] h-[2.1rem]" />
                    Logout
                </button>
            </div>
        </div>
    );
};

export default BrandSidebar;
