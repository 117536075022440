import React, { useState } from "react";
import './MyCampaigns';
import { MyCampaignsImg } from "../../../static/constant/imgConstants";

const BasicDetails = () => {
    const [campaignName, setCampaignName] = useState("");
    const [campaignPurpose, setCampaignPurpose] = useState("");
    const [platform, setPlatform] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const isNext = campaignName || campaignPurpose || selectedType;

    const handleSelect = (type) => {
        setSelectedType(type);
    };

    const cardClass = (type) =>
        `flex flex-col items-center justify-center border rounded-lg p-6 cursor-pointer 
        ${selectedType === type ? 'border-green-500 bg-green-100' : 'border-gray-300 bg-white'} 
        hover:border-green-500 hover:bg-green-50`;

    return (
        <div className="flex flex-col w-full h-full items-center bg-[#EDEEF1] py-8 px-4">
            <div className="w-full h-full flex flex-col px-[1rem]">
                {/* Header */}
                <header className="w-full flex p-4 justify-between border-b bg-[#EDEEF1]">
                    <div className="text-[1.4rem] text-[#38414A] flex items-center space-x-2 mb-4">
                        <a href="#" className="hover:underline inter-font font-[500]">Home</a>
                        <span>{'>'}</span>
                        <span className="hover:underline inter-font font-[500]">My Campaigns</span>
                        <span>{'>'}</span>
                        <span className="hover:underline inter-font font-[500] text-[#0D9B50]">Basic Details</span>
                    </div>
                </header>

                {/* Content */}
                <div className="w-full h-[98%] overflow-auto scrollable"> {/* Ensure parent has a defined height */}
                    <div className="flex flex-col justify-start items-center md:flex-row my-[4rem]">
                        <button className="bg-[#E3E5E9] text-[1.4rem] inter-font font-[500] text-[#38414A] text-center py-4 px-16 rounded-[1rem]">
                            Basic Details
                        </button>
                        <div className="border border-[#C5C6C8] w-[4rem] h-[0.1rem]"></div>
                        <button className="bg-[#E3E5E9] text-[1.4rem] inter-font font-[500] text-[#38414A] text-center py-4 px-16 rounded-[1rem]">
                            Requirement
                        </button>
                        <div className="border border-[#C5C6C8] w-[4rem] h-[0.1rem]"></div>
                        <button className="bg-[#E3E5E9] text-[1.4rem] inter-font font-[500] text-[#38414A] text-center py-4 px-16 rounded-[1rem]">
                            Compensation
                        </button>
                        <div className="border border-[#C5C6C8] w-[4rem] h-[0.1rem]"></div>
                        <button className="bg-[#E3E5E9] text-[1.4rem] inter-font font-[500] text-[#38414A] text-center py-4 px-16 rounded-[1rem]">
                            Deliverables
                        </button>
                    </div>

                    <div className="w-full bg-[#FFFFFF66] rounded-[0.6rem] p-8">
                        <div className="flex flex-col md:flex-row gap-[2.5rem] mb-[2.8rem]">
                            <div className="w-[40rem] sm:w-[60.4rem] text-[1.6rem] inter-font font-[400]">
                                <label className="text-[#111729] mb-4">Campaign Name<span className="text-red-500">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Enter a Campaign Name"
                                    className="w-full px-4 py-4 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
                                    value={campaignName}
                                    onChange={(e) => setCampaignName(e.target.value)}
                                />
                            </div>
                            <div className="w-[40rem] sm:w-[60.4rem] text-[1.6rem] inter-font font-[400]">
                                <label className="text-[#111729] mb-4">Purpose of the Campaign<span className="text-red-500">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Enter a Purpose of the Campaign"
                                    className="w-full px-4 py-4 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
                                    value={campaignPurpose}
                                    onChange={(e) => setCampaignPurpose(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="mb-6 w-[40rem] sm:w-[60.4rem]">
                            <label className="text-[1.6rem] inter-font font-[400] text-[#111729] mb-2">Social Media Platform<span className="text-red-500">*</span></label>
                            <select
                                className="w-full px-4 py-4 text-[1.6rem] inter-font font-[400] border border-gray-300 rounded-lg focus:outline-none"
                                value={platform}
                                onChange={(e) => setPlatform(e.target.value)}
                            >
                                <option value="" disabled>Select Platform</option>
                                <option value="YouTube">YouTube</option>
                                <option value="Instagram">Instagram</option>
                            </select>
                        </div>

                        <div className="w-full my-[2.5rem]">
                            <h1 className="text-[1.6rem] text-[#111729] inter-font font-[400] mb-4">Select Campaign Type<span className="text-red-500">*</span></h1>
                            <div className="space-y-4">
                                <div className="flex flex-col md:flex-row justify-start items-center gap-4">
                                    <div className="flex">
                                        <label className="flex items-start cursor-pointer">
                                            <input
                                                type="radio"
                                                name="campaignType"
                                                className="form-radio h-5 w-5 text-green-600 mr-2"
                                                checked={selectedType === 'Banner'}
                                                onChange={() => handleSelect('Banner')}
                                            />
                                        </label>
                                        <div
                                            className={cardClass('Banner')}
                                            onClick={() => handleSelect('Banner')}
                                        >
                                            <div className="w-[17.9rem] h-[19rem] flex flex-col items-center justify-center mb-4">
                                                <div className=" w-[3.1rem] h-[3.1rem]">
                                                <img src={MyCampaignsImg.mycampaigns1} alt="Banner Icon" className="w-full h-full" />
                                                </div>
                                                <div className="mt-[4rem] ">
                                                <span className="text-[#111729] inter-font font-[400] text-[1.6rem] text-center">Banner</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <label className="flex items-start cursor-pointer">
                                            <input
                                                type="radio"
                                                name="campaignType"
                                                className="form-radio h-5 w-5 text-green-600 mr-2"
                                                checked={selectedType === 'Video'}
                                                onChange={() => handleSelect('Video')}
                                            />
                                        </label>
                                        <div
                                            className={cardClass('Video')}
                                            onClick={() => handleSelect('Video')}
                                        >
                                            <div className="flex-col justify-center items-center w-[17.9rem] h-[19rem] flex mb-4">
                                                <div className="flex justify-center items-center w-[3.1rem] h-[3.1rem]">
                                                <img src={MyCampaignsImg.mycampaigns2} alt="Video Icon" className="w-full h-full" />
                                                </div>
                                            
                                            <div className="mt-[4rem] flex justify-center items-center">
                                            <span className="text-center text-[#111729] inter-font font-[400] text-[1.6rem]">Video</span>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <label className="flex items-start cursor-pointer">
                                            <input
                                                type="radio"
                                                name="campaignType"
                                                className="form-radio h-5 w-5 text-green-600 mr-2"
                                                checked={selectedType === 'User Generated Content'}
                                                onChange={() => handleSelect('User Generated Content')}
                                            />
                                        </label>
                                        <div
                                            className={cardClass('User Generated Content')}
                                            onClick={() => handleSelect('User Generated Content')}
                                        >
                                            <div className="w-[17.9rem] h-[19rem] flex flex-col items-center justify-center mb-4">
                                                <div className=" w-[3.1rem] h-[3.1rem]">
                                                <img src={MyCampaignsImg.mycampaigns3} alt="Banner Icon" className="w-full h-full" />
                                                </div>
                                                <div className="mt-[4rem] ">
                                                <span className="text-[#111729] inter-font font-[400] text-[1.6rem] text-center">User Generated Content</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-start items-center mt-[2.8rem]">
                                <label className="flex items-start cursor-pointer">
                                    <input
                                        type="radio"
                                        name="campaignType"
                                        className="form-radio h-5 w-5 text-green-600 mr-2"
                                        checked={selectedType === ''}
                                    />
                                </label>
                            <p className="text-[#8A98AC] text-[1.6rem] inter-font font-[400] ">Validate that all fields are mandatory before moving to the next step.</p>
                        </div>
                        <div className="flex justify-start gap-[3rem] mt-[3.8rem] mb-[10.5rem]">
                            <button className="bg-gray-200 text-gray-700 text-center text-[1.8rem] inter-font font-[400] py-4 px-16 rounded-lg">Back</button>
                            <button
                                className={`py-4 px-16 rounded-lg text-center text-[1.8rem] inter-font font-[400] ${isNext
                                    ? 'bg-gray-300 text-gray-500'
                                    : 'bg-green-500 text-green-500 cursor-pointer'}`}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicDetails;
