import React from "react";
import { SidebarImg } from '../../../static/constant/imgConstants';


const SocialAccounts = () => {
  return (
    <div className="w-full h-full bg-[#EDEEF1] !overflow-scroll">
      <div className="w-full flex flex-col">
        <header className="w-full flex p-4 justify-between border-b bg-[#EDEEF1]">
          <div className="text-[1.4rem] text-[#38414A] flex items-center space-x-2 mb-4">
            <a href="#" className="hover:underline">Settings</a>
            <span>{'>'}</span>
            <span className="font-medium">Account Details</span>
          </div>
        </header>

        <div className='w-full my-[2rem] !overflow-scroll'>
          <div className="w-[65%] p-6 justify-between bg-white rounded-[2rem] mx-[2rem] !overflow-scroll">
            <div className='w-full flex justify-between'>
              <h2 className="text-[2rem] text-[#202224]  font-semibold">Social Accounts</h2>
              <div className=''>
                <a href="#" className="mt-2">
                  <img src={SidebarImg.union} alt="Wishlist" className="w-[2.1rem] h-[1.9rem] mr-2" />
                </a>
              </div>
            </div>

            <div className="w-full my-[2rem]">
              <h2 className="text-[1.5rem] text-[#000000]  font-semibold">FaceBook</h2>
              <div className="w-full flex justify-start items-center space-x-4 p-4 ">
                <div className="w-[4.5rem] h-[4.5rem]"><img src={SidebarImg.facebook1} alt="Facebook Logo" className="w-full h-full" /></div>
                <div className="w-[44rem] h-[4.5rem]"><input type="text" placeholder="Facebook Profile" className="text-[1.6rem] w-full h-full text-[#677489] border border-gray-300 rounded-lg p-2" /></div>
                <div className="w-[15rem] h-[4.5rem]"><button className="w-full h-full bg-gray-100 text-black text-[1.6rem] py-2 px-4 rounded-lg">
                  Connect
                </button>
                </div>
              </div>
              <h2 className="text-[1.5rem] text-[#000000]  font-semibold">LinkedIn</h2>
              <div className="w-full flex justify-start items-center space-x-4 p-4  ">
                <div className="w-[4.5rem] h-[4.5rem]"><img src={SidebarImg.linkedin} alt="Linkedin Logo" className="w-full h-full" /></div>
                <div className="w-[44rem] h-[4.5rem]"><input type="text" placeholder="Linkedin Profile" className="text-[1.6rem] w-full h-full text-[#677489] border border-gray-300 rounded-lg p-2" /></div>
                <div className="w-[15rem] h-[4.5rem]"><button className="w-full h-full bg-gray-100 text-black text-[1.6rem] py-2 px-4 rounded-lg">
                  Connect
                </button>
                </div>
              </div>
              <h2 className="text-[1.5rem] text-[#000000]  font-semibold">Instagram</h2>
              <div className="w-full flex justify-start items-center space-x-4 p-4  ">
                <div className="w-[4.5rem] h-[4.5rem]"><img src={SidebarImg.insta} alt="Instagram Logo" className="w-full h-full" /></div>
                <div className="w-[44rem] h-[4.5rem]"><input type="text" placeholder="Instagram Profile" className="text-[1.6rem] w-full h-full text-[#677489] border border-gray-300 rounded-lg p-2" /></div>
                <div className="w-[15rem] h-[4.5rem]"><button className="w-full h-full bg-gray-100 text-black text-[1.6rem] py-2 px-4 rounded-lg">
                  Connect
                </button>
                </div>
              </div>

              <div className="my-[2rem]">
                <div className="w-full ">
                  <h2 className="text-[2rem] font-semibold mb-4">Other Social Platforms</h2>

                  {/* Description Input */}
                  <div className="">
                    <div className="w-full flex my-[2rem] gap-4">
                      <div className="flex-1 h-[4.5rem]">
                        <input
                          type="text"
                          placeholder="Description"
                          className="w-full h-full text-[1.6rem] p-3 border border-gray-300 rounded-lg"
                        />
                      </div>
                      <div className="flex-shrink-0 w-full max-w-[12rem] h-[4.5rem] flex items-center justify-center">
                        <button className="w-full h-full flex justify-center items-center text-[1.6rem] bg-green-100 text-green-600 px-4 py-2 rounded-lg">
                          Connected
                        </button>
                      </div>
                    </div>


                    {/* Reference URL Input */}
                    <div className="w-full flex mb-4 gap-4">
                      <div className="w-full flex gap-4">
                        <div className="w-[43rem] h-[4.5rem]">
                          <input
                            type="text"
                            placeholder="Reference URL (eg: http://www.denzo.com)"
                            className="w-full h-full text-[1.6rem] p-3 border border-gray-300 rounded-lg"
                          />
                        </div>
                      </div>

                      {/* Trash Icon and Plus Icon */}
                      <div className="w-full flex ml-4 items-center gap-4">
                        <div className="w-[3rem] h-[3rem] overflow-hidden">
                          <button className="mr-2">
                            <img
                              src={SidebarImg.bin}
                              alt="Trash Icon"
                              className="w-full h-full"
                            />
                          </button>
                        </div>

                        <div className="w-[3rem] h-[3rem] overflow-hidden">
                          <button>
                            <img
                              src={SidebarImg.plus}
                              alt="Plus Icon"
                              className="w-full h-full"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Support Section */}
                  <div className="mt-6 text-[1.6rem]">
                    Having Trouble with connecting to social platform?{' '}
                    <a href="#" className="text-green-600">
                      Please contact Support
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialAccounts;
