import { actionTypes } from '../../types';

// Action creator for generating OTP request
export const getUserInfo = (payload) => ({
    type: actionTypes.GET_USER_INFO_REQUEST,
    payload,
});

// Action creator for successful OTP generation
export const getUserInfoSuccess = (payload) => ({
    type: actionTypes.GET_USER_INFO_SUCCESS,
    payload,
});

// Action creator for failed OTP generation
export const getUserInfoFailure = (payload) => ({
    type: actionTypes.GET_USER_INFO_FAILURE,
    payload,
});

// Action creator for resetting OTP generation status
export const getUserInfoReset = () => ({
    type: actionTypes.GET_USER_INFO_RESET,
});

// Action creator for resetting all OTP generation reducer
export const getUserInfoResetAll = () => ({
    type: actionTypes.GET_USER_INFO_RESET_ALL,
}); 