import React from 'react';
import { useSelector } from 'react-redux';
import { SidebarImg } from '../../../static/constant/imgConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Header = () => {

  const getUserInfoReducer = useSelector(state => state.getUserInfoReducer);

  console.log("getUserInfoReducer?.data :", getUserInfoReducer?.data);


  return (
    <header className="w-full h-[7.1rem] flex justify-between items-center p-4 bg-white border-b">
      <div className="w-[20%] ml-8">
        <img className="w-[7rem] h-full" alt="" src={SidebarImg.login1} />
      </div>

      <div className="w-full flex justify-end items-center mr-[1rem] md:mr-[4rem]">
        <div className="flex justify-center items-center border rounded-[1rem] p-[0.5rem] mr-[4rem] md:mr-[14rem] w-[21.5rem] h-[3.8rem]">
          <FontAwesomeIcon icon={faSearch} className="text-gray-400 text-[1.6rem] mr-6" />
          <input
            type="text"
            placeholder="Search"
            className="w-full h-full text-[1.6rem] border-none focus:outline-none"
          />
        </div>

        <div className='flex justify-center'>
          <button className='w-[5rem]  h-[4rem] flex justify-center items-center bg-[#FD6C55] hover:bg-[#FD6D77] rounded-[2rem] mr-[0.5rem] md:mr-[2rem]'>
            <img src={SidebarImg.notification} alt="Notification" className="w-[2rem] h-[2rem] justify-center items-center" />
          </button>
        </div>

        {/* User Info Section */}
        <div className="flex items-center">
          <img className="w-[3.6rem] h-[3.6rem] rounded-full" src={SidebarImg.profile} alt="User Avatar"/>
          <span className="w-[6.9rem] mr-[0.5rem] md:mr-[2rem] text-[1.5rem] text-center">
            {getUserInfoReducer?.data?.firstName} {/* Dynamic name from getUserInfoReducer?.data */}
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header;
