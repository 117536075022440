import React, { useEffect } from 'react';
import { SidebarImg } from '../../../static/constant/imgConstants';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCountry } from '../../../store/actions/publicActions/getAllCountryActions'; 
import DropdownWithSearch from '../../common/DropdownWithSearch/DropdownWithSearch';

const AccountDetails = () => {
    const dispatch = useDispatch();

    const { countries } = useSelector(state => state.getAllCountryReducer); 
    const [selectedCountryCode, setSelectedCountryCode] = React.useState('');

    useEffect(() => {
        dispatch(getAllCountry());
    }, [dispatch]);

    const handleCountryChange = (countryCode) => {
        setSelectedCountryCode(countryCode);
    };

    return (
        <div className="w-full h-full bg-[#EDEEF1] overflow-scroll">
            {/* Breadcrumb */}
            <div className="flex justify-start items-center border-b bg-[#EDEEF1] mx-[2rem] p-4">
                <div className="text-[1.4rem] text-[#38414A] flex items-center space-x-2 mb-4">
                    <a href="#" className="hover:underline">Settings</a>
                    <span>{'>'}</span>
                    <span className="font-medium">Account Details</span>
                </div>
            </div>

            {/* Account Details Content */}
            <div className='w-full flex flex-col my-[2rem]'>
                <div className="flex flex-col w-[65%] p-[2rem] justify-between bg-white rounded-[2rem] mx-[2rem]">
                    <div className='w-full flex justify-between'>
                        <h2 className="text-[2rem] font-semibold text-[#202224]">Account Details</h2>
                        <div className=''>
                            <a href="#" className="mt-2">
                                <img src={SidebarImg.union} alt="Wishlist" className="w-[2.1rem] h-[1.9rem] mr-2" />
                            </a>
                        </div>
                    </div>
                    <div className='w-full flex flex-col md:flex-row justify-start items-end gap-8 my-[2rem] mb-[3rem]'>
                        <div className='w-full md:w-[80%] flex flex-col gap-4'>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-[3rem] mt-4">
                                <div>
                                    <label className="text-[1.6rem] text-[#111729]">First Name</label>
                                    <input type="text" className="mt-1 h-[4rem] text-[1.6rem] p-4 border rounded-[0.5rem] w-full" placeholder="Enter First Name" />
                                </div>
                                <div>
                                    <label className="text-[1.6rem] text-[#111729]">Last Name</label>
                                    <input type="text" className="mt-1 h-[4rem] text-[1.6rem] p-4 border rounded-[0.5rem] w-full" placeholder="Enter Last Name" />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-[3rem] mt-4">
                                <div>
                                    <label className="text-[1.6rem] text-[#111729]">Email (Login ID)</label>
                                    <input type="email" className="mt-1 text-[1.6rem] h-[4rem] p-4 border rounded-[0.5rem] w-full" placeholder="Enter Email ID" />
                                </div>
                                <div>
                                    <label className="text-[1.6rem] text-[#111729]">Phone Number</label>
                                    <div className="flex items-center rounded-[0.5rem] mt-1">
                                        <DropdownWithSearch
                                            options={countries || []}
                                            value={selectedCountryCode}
                                            onChange={handleCountryChange}
                                            placeholder="+91"
                                            className="p-4 h-[4rem] text-[1.5rem] justify-center items-center text-center border rounded-r focus:outline-none bg-gray-100"
                                        />
                                        <input type="tel" className="p-4 text-[1.6rem] h-[4rem] border rounded-r w-full" placeholder="Enter Phone Number" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full md:w-auto flex md:flex-col gap-4'>
                            <button className="text-[#0D9B50] hover:text-[#ffffff] hover:bg-[#0D9B50] text-[1.6rem] border border-[#0D9B50] px-4 py-2 w-full md:w-[7.8rem] h-[3.5rem] md:h-[4rem] rounded-[0.5rem]">
                                Verify
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Password Section */}
            <div className='w-full flex flex-col my-[4rem]'>
                <div className='w-[65%] bg-white p-6 justify-center rounded-[2rem]  mx-[2rem] gap-4'>
                    <h1 className=" text-[2rem] text-[#000000] font-semibold">Password</h1>
                    <div className='w-full justify-start items-end gap-4'>
                        <div className='w-[70%] flex flex-col md:flex-row items-end gap-[2rem] my-[2rem] mb-[3rem]'>
                            <div className="w-full flex-1">
                                <div className="w-full grid gap-4 mt-4">
                                    <div className='w-full'>
                                        <label className="text-[1.6rem] text-[#111729]">Password</label>
                                        <input
                                            type="password"
                                            className="mt-1 p-4 text-[1.6rem] h-[4rem] border rounded-[0.5rem] w-full"
                                            placeholder="Enter Password"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='w-full md:w-auto justify-center'>
                                <button className="text-[#0D9B50] hover:text-[#ffffff] hover:bg-[#0D9B50] text-[1.6rem] text-center border border-[#0D9B50] px-4 py-2 w-full md:w-[16.8rem] h-[3.5rem] md:h-[4rem] rounded-[0.5rem]">
                                    Change Password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <div className='flex mt-auto justify-center'>
                <div className="flex justify-center items-center text-[#959CB6] mt-auto mx-auto text-center text-[1.6rem]">
                    © 2024 ALL RIGHTS RESERVED
                </div>
                <div className="text-[1.4rem] text-[#8A98AC] flex justify-end mx-auto space-x-2 gap-2 mr-8">
                    <a href="#" className="hover:underline">Privacy Policy</a>
                    <a href="#" className="hover:underline">Cookies</a>
                </div>
            </div>
        </div>
    );
};

export default AccountDetails;
