import React from 'react';
import Sidebar from '../../components/main/Sidebar/Sidebar';
import Header from '../../components/main/Header/Header';
import Footer from '../../components/main/Footer/Footer';
import ShippingAddress from '../../components/main/ShippingAddress/ShippingAddress';

const DashboardShippingAddress = () => {

  return (
    <div className="flex flex-col h-[100vh]">
                {/* Header */}
                <Header />
            <div className='flex justify-between h-[90%]'>
                    <Sidebar />
                <div className='w-[80%]'>
                    <ShippingAddress />
                </div>
            </div>
        </div>
  )
}

export default DashboardShippingAddress