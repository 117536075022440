import React from 'react';
import { useDispatch } from "react-redux";
import { logout } from '../../../store/actions/utilsActions/utilsActions';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaHome, FaHeart, FaCog, FaSignOutAlt, FaBullhorn } from 'react-icons/fa'; // Import required icons
import { SidebarImg } from '../../../static/constant/imgConstants';

const Sidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation(); // Get the current location

    // Function to handle menu navigation
    const handleMenuClick = (path) => {
        navigate(path);
    };

    // Function to check if the menu or submenu is selected
    const isSelected = (path) => location.pathname === path;

    return (
        <div className="w-[40%] sm:w-[25%] md:w-[20%] h-full flex flex-col px-[1rem]">

            <div className="w-full h-full text-center">
                <ul className="space-y-2 mt-[1rem] text-center">

                    {/* Home */}
                    <li>
                        <a 
                            onClick={() => handleMenuClick('/')} 
                            className={`text-[1.6rem] text-[#000000] rounded-[0.8rem] p-4 flex items-center gap-4 ml-4 
                                ${isSelected('/') ? "bg-[#0D9B50] text-white" : "hover:bg-[#0D9B50] hover:text-[#ffffff]"}`}
                        >
                            <FaHome className="w-[2.1rem] h-[2.1rem] mr-2" /> {/* Use icon */}
                            Home
                        </a>
                    </li>

                    {/* Campaigns */}
                    <li>
                        <a 
                            onClick={() => handleMenuClick('/campaigns')} 
                            className={`text-[1.6rem] text-[#000000] rounded-[0.8rem] p-4 flex items-center gap-4 ml-4 
                                ${isSelected('/campaigns') ? "bg-[#0D9B50] text-white" : "hover:bg-[#0D9B50] hover:text-[#ffffff]"}`}
                        >
                            <FaBullhorn className="sm:w-[2.1rem] sm:h-[2.1rem] mr-2" /> {/* Use icon */}
                            Campaigns
                        </a>
                    </li>

                    {/* Wishlist */}
                    <li>
                        <a 
                            onClick={() => handleMenuClick('/wishlist')} 
                            className={`text-[1.6rem] text-[#000000] rounded-[0.8rem] p-4 flex items-center gap-4 ml-4 
                                ${isSelected('/wishlist') ? "bg-[#0D9B50] text-white" : "hover:bg-[#0D9B50] hover:text-[#ffffff]"}`}
                        >
                            <FaHeart className="w-[2.3rem] h-[2.1rem] mr-2" /> {/* Use icon */}
                            Wishlist
                        </a>
                    </li>

                    {/* Settings */}
                    <li>
                        <div 
                            onClick={() => handleMenuClick('/dashboard-landing')} 
                            className={`text-[1.6rem] text-[#000000] rounded-[0.8rem] p-4 flex items-center gap-4 ml-4 
                                ${location.pathname.startsWith('/dashboard') ? "bg-[#0D9B50] text-white" : "hover:bg-[#0D9B50] hover:text-[#ffffff]"}`}
                        >
                            <FaCog className="w-[2.1rem] h-[2.1rem] mr-2" /> {/* Use icon */}
                            Settings
                        </div>

                        <ul className="text-[1.6rem] ml-10 space-y-2 justify-start  px-[1rem] sm:px-[2rem] mt-[1.5rem]">
                            <li>
                                <button
                                    onClick={() => handleMenuClick('/dashboard-landing')}
                                    className={`text-[#000000] w-full flex items-start text-start p-4 rounded-[1rem] 
                                        ${isSelected('/dashboard-landing') ? "bg-[#0D9B501A]" : "hover:bg-[#0D9B501A]"}`}
                                >
                                    Account Details
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => handleMenuClick('/dashboard-shipping-address')}
                                    className={`text-[#000000] w-full flex Items-start text-start p-4 rounded-[1rem] 
                                        ${isSelected('/dashboard-shipping-address') ? "bg-[#0D9B501A]" : "hover:bg-[#0D9B501A]"}`}
                                >
                                    Shipping Address
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => handleMenuClick('/miscellaneous-details')}
                                    className={`text-[#000000] w-full flex Items-start text-start p-4 rounded-[1rem] 
                                        ${isSelected('/miscellaneous-details') ? "bg-[#0D9B501A]" : "hover:bg-[#0D9B501A]"}`}
                                >
                                    Miscellaneous Details
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => handleMenuClick('/dashboard-social-account')}
                                    className={`text-[#000000] w-full flex Items-start text-start p-4 rounded-[1rem] 
                                        ${isSelected('/dashboard-social-account') ? "bg-[#0D9B501A]" : "hover:bg-[#0D9B501A]"}`}
                                >
                                    Social Accounts
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => handleMenuClick('/pay-out-dashboard')}
                                    className={`text-[#000000] w-full flex Items-start text-start p-4 rounded-[1rem] 
                                        ${isSelected('/pay-out-dashboard') ? "bg-[#0D9B501A]" : "hover:bg-[#0D9B501A]"}`}
                                >
                                    Payout Settings
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div className='mt-auto mb-[1rem] p-4'>
                <button 
                    className="w-[20rem] text-[1.8rem] text-[#505050] hover:text-[#ffffff] mt-auto rounded-[1.5rem] flex items-center p-4 gap-2 ml-8 hover:bg-green-700" 
                    onClick={() => { dispatch(logout()) }}
                >
                    <img src={SidebarImg.logoutimg} alt="Logout" className="w-[2.1rem] h-[2.1rem] gap-2 " />
                    Logout
                </button>
            </div>
        </div>
    );
};

export default Sidebar;
