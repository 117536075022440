import React from 'react';
import Sidebar from '../../components/main/Sidebar/Sidebar';
import Header from '../../components/main/Header/Header';
import SocialAccounts from '../../components/main/SocialAccounts/SocialAccounts';
import Footer from '../../components/main/Footer/Footer';

const DashboardSocialAccount = () => {


  return (
    <div className="flex flex-col">
            {/* Header */}
            <Header />
            <div className='flex justify-between h-[90%]'>
                    <Sidebar />
                <div className='w-[80%]'>
                    <SocialAccounts />
                </div>
            </div>
        </div>
  )
}

export default DashboardSocialAccount