import React, { useState, useEffect } from 'react';
import { LoginPageImg } from '../../static/constant/imgConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '../../hooks/useToast';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import PublicHeader from '../../components/utility/PublicHeader/PublicHeader';
import { verifyOtpForLogin, verifyOtpForLoginReset } from '../../store/actions/authActions/verifyOtpForLoginActions';
import { FaCheck, FaTimes } from 'react-icons/fa';
import VerifyPopup from '../../components/main/VerifyPopup/VerifyPopup';
import { getUserInfo, getUserInfoReset } from '../../store/actions/authActions/getUserInfoActions';

function VerifyEmailOtp() {
    const dispatch = useDispatch();
    const Toast = useToast();
    const { userType } = useParams();
    const navigate = useNavigate();

    // Get loginType from localStorage or default to 'Influencer'
    // const loginType = localStorage.getItem('loginType') || 'Influencer';

    const utilReducers = useSelector(state => state.utilReducers);
    const getUserInfoReducer = useSelector(state => state.getUserInfoReducer);
    const verifyOtpForLoginReducer = useSelector(state => state.verifyOtpForLoginReducer);

    const [otpEmail, setOtpEmail] = useState(new Array(4).fill(''));
    const [message, setMessage] = useState('');
    const [apiStatus, setApiStatus] = useState(null);
    const [popupMessage, setPopupMessage] = useState(false);

    const handleChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]?$/.test(value)) { // Only allow numeric input
            const newOtp = [...otpEmail];
            newOtp[index] = value;
            setOtpEmail(newOtp);

            // Move to the next input if current one is filled
            if (value && index < 3) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        // Move to the previous input if Backspace is pressed and input is empty
        if (e.key === 'Backspace' && !otpEmail[index] && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData('text').slice(0, 4).split('');
        if (/^\d+$/.test(paste.join(''))) {
            setOtpEmail(paste);
        }
    };

    const handleVerify = () => {
        // Ensure that the OTP entered is of valid length
        if (otpEmail.join('').length !== 4) {
            setMessage('Please enter valid OTP');
        } else {
            setMessage('');

            // Dispatch the action to verify OTP
            dispatch(verifyOtpForLogin({
                email: utilReducers?.emailForLogin, // Update with actual email
                sessionCode: utilReducers.sessionToken,
                emailOtp: otpEmail.join(''),
                isBrand: userType === "Brand"
            }));
        }
    };

    useEffect(() => {
        if (verifyOtpForLoginReducer.status === 200) {
            setApiStatus(verifyOtpForLoginReducer.status);
            Toast(verifyOtpForLoginReducer.message, 'success');
            dispatch(getUserInfo());
            dispatch(verifyOtpForLoginReset());
        } else if (verifyOtpForLoginReducer.status === 403 || verifyOtpForLoginReducer.status === 302) {
            setApiStatus(verifyOtpForLoginReducer.status);
            setMessage(verifyOtpForLoginReducer.message);
            dispatch(verifyOtpForLoginReset());
        } else if (verifyOtpForLoginReducer.status === 303) {
            setApiStatus(verifyOtpForLoginReducer.status);
            Toast(verifyOtpForLoginReducer.message, 'error');
            dispatch(verifyOtpForLoginReset());
            navigate(`/`);
        } else if (verifyOtpForLoginReducer.status === 305) {
            setPopupMessage(verifyOtpForLoginReducer.message)
            dispatch(verifyOtpForLoginReset());
        } else if (verifyOtpForLoginReducer.status) {
            setApiStatus(verifyOtpForLoginReducer.status);
            setMessage(verifyOtpForLoginReducer.message);
            dispatch(verifyOtpForLoginReset());
        }

        console.log('verifyOtpForLoginReducer:', verifyOtpForLoginReducer);
    }, [verifyOtpForLoginReducer]);

    useEffect(() => {
        if (getUserInfoReducer.status === 200) {
            dispatch(getUserInfoReset());
            if(userType === "Brand")navigate(`/dashboard-brand`);
            else navigate(`/`);
        } else if (getUserInfoReducer.status === 403) {
            Toast(getUserInfoReducer.message, 'error');
            dispatch(getUserInfoReset());
        } else if (getUserInfoReducer.status) {
            Toast(getUserInfoReducer.message, 'info');
            dispatch(getUserInfoReset());
        }
    }, [getUserInfoReducer]);

    return (
        <div className='flex flex-col lg:flex-row w-full h-full  sm:justify-between items-center lg:items-start overflow-auto lg:overflow-hidden'>
            <VerifyPopup message={popupMessage} isOpen={popupMessage} modalAction={() => navigate('/')} type="warning"/>
            <div className='flex flex-col justify-start items-center w-full lg:w-[58%] min-h-max lg:h-full px-[4.8rem] py-[3rem]'>
                <PublicHeader />
                <div className='flex flex-grow flex-col justify-start items-start gap-[1rem] lg:gap-[0rem] text-[1.6rem] w-[90%] sm:w-[80%] md:w-[60%] lg:w-[40%] min-h-max '>
                    <h2 className="text-[1.6rem] text-left md:text-[1.8rem] inter-font font-[600] mb-3 mt-[3rem] lg:mt-[6rem]">
                        Email OTP
                    </h2>
                    <p className="text-[1.4rem] text-start inter-font font-[400] md:text-[1.6rem] text-[#313957] mb-[4rem]">
                        Enter your 4 digits code that you received on your email.
                    </p>
                    <div className="flex justify-between items-center w-full" onPaste={handlePaste}>
                        {otpEmail.map((value, index) => (
                            <input
                                key={index}
                                id={`otp-input-${index}`}
                                aria-label={`OTP input ${index + 1}`}
                                type="text"
                                maxLength="1"
                                value={value}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                className={`w-[4rem] lg:w-[5rem] h-[4rem] lg:h-[5rem] border rounded-[.8rem] text-center text-[1.6rem] ${message ? 'border-red-500' : 'border-gray-300'}`}
                            />
                        ))}
                        <div className='w-[16%]'>
                            {apiStatus ?
                                apiStatus === 200 ? <FaCheck className='text-green-500' size='20' /> : <FaTimes className='text-red-500' size='20' />
                                : null
                            }
                        </div>
                    </div>
                    {message && <p className="text-left text-red-500 text-[1.6rem]">{message}</p>}
                    <button
                        type="submit"
                        className="w-full bg-[#0D9B50] text-[2rem] roboto-thin font-[400] text-white py-3 mb-4 mt-[6rem] rounded-[1.2rem] hover:bg-green-700"
                        onClick={handleVerify}
                    >
                        Submit
                    </button>
                </div>
            </div>
            <div className='flex justify-center items-center w-[90%] sm:w-[80%] md:w-[60%] lg:w-[42%] min-h-max lg:h-full'>
                <img src={LoginPageImg.loginlogo} alt="" className="w-full h-full" />
            </div>
        </div>
    );
}

export default VerifyEmailOtp;
